import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = {
  class: "truncate group-hover:text-clip group-hover:overflow-visible group-hover:bg-white group-hover:py-1 group-hover:pr-2 group-hover:rounded-r-lg"
};
export default {
  __name: 'PageColumnTitleOrUrl',
  props: {
    titleUrlToggle: {
      type: Boolean,
      required: true
    }
  },
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const props = __props;
    const data = computed(() => {
      return props.titleUrlToggle ? item?.meta?.title : item?.canonical_page_id;
    });
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) && __props.titleUrlToggle ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "best_title",
        reverse: ""
      }, {
        title: _withCtx(() => [_createTextVNode(" Title ")]),
        tooltip: _withCtx(() => [_createTextVNode(" The meta title of the page that appears in search results. If none is set, we use the page URL. ")]),
        _: 1
      })) : _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 1,
        "sort-by": "canonical_page_id",
        reverse: ""
      }, {
        title: _withCtx(() => [_createTextVNode(" URL ")]),
        tooltip: _withCtx(() => [_createTextVNode(" The URL of the page, excluding queries. ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 2,
        truncate: false,
        title: _unref(data),
        class: "group relative z-50 font-medium"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, () => [_createTextVNode(_toDisplayString(_unref(data)), 1)])])]),
        _: 3
      }, 8, ["title"]));
    };
  }
};