import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-10"
};
const _hoisted_2 = {
  key: 0,
  class: "copy mb-4 select-none"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
import { useRouteQuery } from "@vueuse/router";
import { getLibraryPages } from "@/api/library";
import { getTag } from "@/api/tags";

import { useRoute } from 'vue-router';
import { ref, reactive, onMounted, watch } from 'vue';
import { PageTableConfig } from '@/utils';
export default {
  __name: 'TagSingle',
  setup(__props) {
    const route = useRoute();
    const monthRange = useRouteQuery("month_range", 3, {
      transform: Number
    });
    const tag = ref(null);
    const conf = reactive(new PageTableConfig({
      sortField: "page_views",
      sortGroup: `org_last_${monthRange.value}_months_data`,
      copyFormat: "pages",
      pageSize: 100,
      checkboxes: true,
      searchText: useRouteQuery("search", ""),
      getAsync: async config => {
        config.tags = [parseInt(route.params.tagId)];
        return await getLibraryPages(route.params.libraryId, config);
      }
    }));
    onMounted(initTable);
    watch(() => route.params.tagId, initTable);
    async function initTable() {
      tag.value = await getTag(route.params.libraryId, route.params.tagId);
      conf.get();
    }
    return (_ctx, _cache) => {
      const _component_CollectionsTable = _resolveComponent("CollectionsTable");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(tag) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(tag).name ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_unref(tag).name), 1)) : _createCommentVNode("", true), _unref(tag)?.description ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(tag).description), 1)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createVNode(_component_CollectionsTable, {
        conf: _unref(conf),
        "onUpdate:conf": _cache[0] || (_cache[0] = $event => _isRef(conf) ? conf.value = $event : null),
        class: "legacy-table"
      }, null, 8, ["conf"])]);
    };
  }
};