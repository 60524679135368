import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue";

import { inject, provide } from 'vue';
const _hoisted_1 = {
  class: "block px-3 h-full flex items-center"
};
export default {
  __name: 'TableRow',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const conf = inject("conf");
    const linkTo = inject("linkTo");
    const props = __props;
    provide("item", props.item);
    provide("header", false);
    return (_ctx, _cache) => {
      const _component_TableCheckbox = _resolveComponent("TableCheckbox");
      const _component_TableCell = _resolveComponent("TableCell");
      const _component_TableCellCaret = _resolveComponent("TableCellCaret");
      return _openBlock(), _createElementBlock("tr", null, [_unref(conf)?.checkboxes ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: "hover:cursor-pointer",
        onClick: _cache[0] || (_cache[0] = $event => _unref(conf).toggleCheck(__props.item))
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_TableCheckbox, {
        active: _unref(conf).isChecked(__props.item)
      }, null, 8, ["active"])])])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _unref(linkTo) ? (_openBlock(), _createBlock(_component_TableCell, {
        key: 1
      })) : _createCommentVNode("", true), _unref(linkTo) ? (_openBlock(), _createBlock(_component_TableCellCaret, {
        key: 2
      })) : _createCommentVNode("", true)]);
    };
  }
};