import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";

import { inject, computed } from 'vue';
export default {
  __name: 'KeywordsColumnTopRankingPage',
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const linkType = computed(() => {
      return item?.topPage?.notMatched ? "External" : "PageSingle";
    });
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "topPage.canonical_page_id"
      }, {
        title: _withCtx(() => [_createTextVNode(" Top Ranking URL ")]),
        tooltip: _withCtx(() => [_createTextVNode(" The highest ranking library page for each search term over the last month. ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1,
        item: _unref(item).topPage,
        link: _unref(linkType),
        target: "_blank",
        to: _unref(item)?.topPage?.canonical_page_id
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(item)?.topPage?.canonical_page_id || ""), 1)]),
        _: 1
      }, 8, ["item", "link", "to"]));
    };
  }
};