import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue";

import { inject, computed } from 'vue';
export default {
  __name: 'ClientDashboardColumnStatus',
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const status = computed(() => item ? item.status : "");
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_BaseBadge = _resolveComponent("BaseBadge");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0
      }, {
        title: _withCtx(() => [_createTextVNode(" Comments ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1
      }, {
        default: _withCtx(() => [_unref(status) === 'Review' || _unref(status) === 'QA' ? (_openBlock(), _createBlock(_component_BaseBadge, {
          key: 0,
          color: "amber",
          size: "lg",
          fill: "light",
          class: "w-full text-center"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(status)), 1)]),
          _: 1
        })) : _unref(status) === 'Working' || _unref(status) === 'Revise' ? (_openBlock(), _createBlock(_component_BaseBadge, {
          key: 1,
          color: "blue",
          size: "lg",
          fill: "light",
          class: "w-full text-center"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(status)), 1)]),
          _: 1
        })) : _unref(status) === 'Next Up' ? (_openBlock(), _createBlock(_component_BaseBadge, {
          key: 2,
          color: "yellow",
          size: "lg",
          fill: "light",
          class: "w-full text-center"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(status)), 1)]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_BaseBadge, {
          key: 3,
          size: "lg",
          fill: "light",
          class: "w-full text-center"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(status)), 1)]),
          _: 1
        }))]),
        _: 1
      }));
    };
  }
};