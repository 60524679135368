import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import escapeStringRegexp from "escape-string-regexp";
import Fuse from "fuse.js";
import { getLibraryPages, getLibraryGscWithRegex, getLibraryStatus } from "@/api/library";
import { listTags } from "@/api/tags";
import { mapLibraryStatus, urlToCanonicalPageId, getGscPageRegexObject } from "@/utils";
export const useLibraryStore = defineStore("library", () => {
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  const libraryInit = {
    id: null,
    name: null,
    status: null,
    ga4PropertyId: null,
    uaPropertyId: null,
    gscSiteurl: null,
    hostnameFilterType: null,
    pathFilterType: null,
    hostnameFilterValues: null,
    pathFilterValues: null,
    pageCount: null,
    pages: [],
    maxViewedPage: null,
    tags: null,
    workspaceId: null,
    workspacePlanType: null
  };
  const library = reactive({
    ...libraryInit
  });
  function resetLibrary() {
    Object.assign(library, libraryInit);
  }
  const allFilters = computed(() => {
    let filters = [];
    const typeMap = {
      "EXCLUDE": "-",
      "INCLUDE": "+"
    };
    if (library.hostnameFilterValues) {
      library.hostnameFilterValues.forEach(filter => {
        filters.push(typeMap[library.hostnameFilterType] + " " + filter);
      });
    }
    if (library.pathFilterValues) {
      library.pathFilterValues.forEach(filter => {
        filters.push(typeMap[library.pathFilterType] + " " + filter);
      });
    }
    if (filters.length - 10 > 0) {
      const count = filters.length - 10;
      filters = filters.slice(0, 10);
      filters.push(`And ${count} more...`);
    }
    return filters;
  });
  const gscPageFilters = computed(() => {
    const filters = [];
    if (library.hostnameFilterValues?.length > 0) {
      const hostnameString = library.hostnameFilterValues.map(hostname => escapeStringRegexp(hostname)).join("|");
      filters.push({
        "dimension": "PAGE",
        "operator": library.hostnameFilterType === "EXCLUDE" ? "EXCLUDING_REGEX" : "INCLUDING_REGEX",
        "expression": `^https?:\\/\\/(www\\.)?(${hostnameString})(\\/.*)?$`
      });
    }
    if (library.pathFilterValues?.length > 0) {
      const pathRegexObject = getGscPageRegexObject(library.pathFilterValues);
      filters.push({
        "dimension": "PAGE",
        "operator": library.pathFilterType === "EXCLUDE" ? "EXCLUDING_REGEX" : "INCLUDING_REGEX",
        "expression": pathRegexObject.regex
      });
    }
    return filters;
  });
  async function loadLibrary(workspaceId, libraryId) {
    resetLibrary();
    if (!libraryId || !workspaceId) return;
    library.id = libraryId;
    library.workspaceId = workspaceId;
    userStore.klaviyoCall("Loaded Library", {
      library_id: libraryId
    });
    await getStatus();
    library.tags = await listTags(library.id);
  }
  async function getStatus() {
    const pollingTime = 5000;
    const data = await getLibraryStatus(library.workspaceId, library.id);
    if (!data) {
      return setTimeout(getStatus, pollingTime);
    } else {
      library.name = data.name;
      document.title = `ércule – ${data.name} library`;
      library.workspaceId = data.workspace;
      library.workspacePlanType = data.workspace_plan_type;
      library.pageCount = data.page_count;
      library.maxViewedPage = data.max_page_views || 10000;
      library.ga4PropertyId = data.ga4_property_id === "" ? null : data.ga4_property_id;
      library.uaPropertyId = data.ua_property_id === "" ? null : data.ua_property_id;
      library.gscSiteurl = data.gsc_siteurl === "" ? null : data.gsc_siteurl;
      library.hostnameFilterType = data.hostname_filter_type;
      library.pathFilterType = data.path_filter_type;
      library.hostnameFilterValues = data.hostname_filter_values;
      library.pathFilterValues = data.path_filter_values;
      const newStatus = mapLibraryStatus(data);
      if (!library.status) {
        library.status = newStatus;
        if (newStatus.tier != 0) {
          // if status is being set for the first time and library is not fully synced
          notificationStore.setNotification("Library data currently syncing...", "This may take a few minutes while we intelligently roll up and process all your data.", "blue");
        }
      } else if (library.status?.tier !== newStatus.tier) {
        library.status = newStatus;
        if (newStatus.tier === 0) {
          // if the status has changed and the library is now fully synced
          notificationStore.setNotification("Library sync complete!", "We've finished your library analysis. Take a look in any of our 'collections' to see what we found.", "green");
        }
      }
      if (library.status?.tier != 0) {
        return setTimeout(getStatus, pollingTime);
      }
    }
  }
  async function getCollectionsPages() {
    const currentID = library.id;
    if (library.pagesSyncing) {
      return await library.pagesPromise;
    } else if (library.pages && library.pages.length > 0) {
      return;
    } else {
      library.pagesSyncing = true;
      library.pagesPromise = getLibraryPages(library.id, {
        ps: 5000,
        pn: 1,
        so_group: "org_last_3_months_data",
        so_field: "page_views"
      });
      const res = await library.pagesPromise;
      library.pagesSyncing = false;
      if (library.id !== currentID) return;
      library.pages = res?.pages ? res.pages.filter(page => !page.collections.isMissing) : [];
    }
  }

  // used for internal exporter page only
  // likely not necessary now that we have url filters
  async function getAllPages(exclusionString) {
    await getCollectionsPages();
    return library.pages.filter(page => {
      let filtered = false;
      const matchKey = "https://" + page.canonical_page_id;
      if (typeof exclusionString == "string" && exclusionString != "") {
        exclusionString.split(",").forEach(str => {
          if (matchKey.toLowerCase().includes(str.trim().toLowerCase())) filtered = true;
        });
      }
      return !filtered;
    });
  }
  function getMatchingPageByUrl(pageUrl) {
    if (!pageUrl) return undefined;
    const matchUrl = urlToCanonicalPageId(pageUrl);
    return library.pages.find(libPage => matchUrl?.toLowerCase() === libPage?.canonical_page_id?.toLowerCase());
  }
  async function getTopicPages(topic, urlExcludeString, topicName) {
    await getCollectionsPages();
    const matchPages = library.pages.map(page => {
      return {
        ...page,
        titleAndUrl: page.meta.title.toLowerCase() + " " + page.meta.url.toLowerCase(),
        searchData: {
          topic: topicName || topic.string,
          clicks: 0,
          impressions: 0,
          keywordCount: 0
        }
      };
    });
    if (library.gscSiteurl) {
      const gscPageData = await getLibraryGscWithRegex(library.id, {
        rowLimit: 100,
        dimensions: ["PAGE"],
        regex: topic.regex
      });
      if (gscPageData) {
        gscPageData.forEach(row => {
          const searchData = {
            ...row,
            topic: topicName || topic.string,
            keywordCount: 0
          };
          const pageUrl = new URL(row.keys[0]);
          let pagePath = pageUrl.pathname;
          pagePath = pagePath.charAt(pagePath.length - 1) == "/" ? pagePath.slice(0, -1) : pagePath;
          const pageKey = pageUrl.hostname + pagePath;
          const existingPageIndex = matchPages.findIndex(page => page.canonical_page_id === pageKey);
          if (existingPageIndex >= 0) {
            matchPages[existingPageIndex].searchData = searchData;
          } else {
            // matchPages.push({
            //   searchData,
            //   id: "",
            //   best_title: "NA",
            //   canonical_page_id: pageKey,
            //   titleAndUrl: pageKey,
            //   meta: { title: "NA", cleanUrl: pageKey },
            //   org1Month: {pageviews: 0}
            // })
          }
        });
      }
    }
    return matchPages.filter(page => {
      let filtered = false;
      const matchKey = "https://" + page.canonical_page_id;
      if (typeof urlExcludeString === "string" && urlExcludeString !== "") {
        urlExcludeString.split(",").forEach(str => {
          if (matchKey.toLowerCase().includes(str.trim().toLowerCase())) filtered = true;
        });
      }
      return !filtered && (page.titleAndUrl.match(topic.regex) || page.searchData.impressions > 0);
    }).map(page => {
      page.matchScore = getHighestFuzzyMatchScore(page.titleAndUrl, topic.terms, page.searchData.impressions);
      return page;
    }).filter(page => page.matchScore > 40);
  }
  function getHighestFuzzyMatchScore(lookIn, lookFor, impressions = 0) {
    let matchScore = 0;
    for (const term of lookFor) {
      matchScore = Math.max(getFuzzyMatchScore(lookIn, term.trim()), matchScore);
    }
    const impressionsScore = impressions == 0 ? 0 : Math.min(impressions + 50, 200) / 2;
    const blendedScore = matchScore * 0.7 + impressionsScore * 0.3;
    return Math.round(Math.max(matchScore, impressionsScore * 0.9, blendedScore));
  }
  function getFuzzyMatchScore(lookIn, lookFor) {
    const fuseOptions = {
      includeScore: true,
      minMatchCharLength: 3,
      ignoreLocation: true
    };
    const fuseMatcher = new Fuse([lookIn], fuseOptions);
    const result = fuseMatcher.search(lookFor);
    return result.length > 0 ? 100 - Math.round(100 * result[0].score) : 0;
  }
  function buildGAPageUrl(pageMeta) {
    if (!pageMeta?.path || !library.ga4PropertyId) return undefined;
    const regex = escapeStringRegexp(pageMeta.path) + "/?$";
    const comparisons = [{
      "name": "All",
      "isEnabled": true,
      "filters": []
    }, {
      "name": "Organic Users",
      "isEnabled": true,
      "filters": [{
        "fieldName": "sessionDefaultChannelGrouping",
        "evaluationType": 3,
        "expressionList": ["Organic Search"],
        "isCaseSensitive": false
      }]
    }];
    const dataFilters = [{
      "type": 1,
      "fieldName": "unifiedPagePathScreen",
      "evaluationType": 2,
      "expressionList": [regex],
      "complement": false,
      "isCaseSensitive": false,
      "expression": ""
    }, {
      "type": 1,
      "fieldName": "hostname",
      "evaluationType": 1,
      "expressionList": [pageMeta.hostname],
      "complement": false,
      "isCaseSensitive": false,
      "expression": ""
    }];
    const viewParamsString = `_u..nav=maui&_r.explorerCard..selmet=["screenPageViews"]&_r.explorerCard..seldim=["unifiedPagePathScreen"]&_r.explorerCard..startRow=0&_u..comparisons=${JSON.stringify(comparisons)}&_u..built_comparisons_enabled=true&_r..dataFilters=${JSON.stringify(dataFilters)}`;
    const baseUrl = "https://analytics.google.com/analytics/web/?";
    const userString = `authuser=${userStore.user?.email}`;
    const viewString = `#/p${library.ga4PropertyId}/reports/explorer?params=${encodeURIComponent(viewParamsString)}&r=all-pages-and-screens`;
    return baseUrl + userString + viewString;
  }
  return {
    ...toRefs(library),
    allFilters,
    loadLibrary,
    resetLibrary,
    getCollectionsPages,
    buildGAPageUrl,
    getTopicPages,
    getAllPages,
    getMatchingPageByUrl,
    gscPageFilters
  };
});