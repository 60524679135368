import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";

import { ref, computed } from 'vue';
import { numDisplay, copyItemsToClipboard } from '@/utils';
const _hoisted_1 = {
  class: "flex items-center"
};
export default {
  __name: 'TableMenuItemCopy',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    format: {
      type: String,
      default: "raw"
    },
    length: {
      type: [Number, null],
      default: null
    }
  },
  setup(__props) {
    const props = __props;
    const itemsCopied = ref(false);
    const displayLength = computed(() => {
      const l = props.length || props.items.length || 0;
      return numDisplay(l, "m");
    });
    function copy() {
      copyItemsToClipboard(props.items, props.format);
      itemsCopied.value = true;
      setTimeout(() => {
        itemsCopied.value = false;
      }, 2000);
    }
    return (_ctx, _cache) => {
      const _component_hero_clipboard_document_check = _resolveComponent("hero-clipboard-document-check");
      const _component_hero_clipboard_document = _resolveComponent("hero-clipboard-document");
      const _component_TableMenuItem = _resolveComponent("TableMenuItem");
      return _openBlock(), _createBlock(_component_TableMenuItem, {
        disabled: __props.items.length === 0,
        onClick: copy
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(itemsCopied) ? (_openBlock(), _createBlock(_component_hero_clipboard_document_check, {
          key: 0,
          class: "w-4 h-4 mr-2 text-green-500"
        })) : (_openBlock(), _createBlock(_component_hero_clipboard_document, {
          key: 1,
          class: "w-4 h-4 mr-2"
        })), _renderSlot(_ctx.$slots, "default"), _createTextVNode(" (" + _toDisplayString(_unref(displayLength)) + ") ", 1)])]),
        _: 3
      }, 8, ["disabled"]);
    };
  }
};