import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";

import { inject } from 'vue';
export default {
  __name: 'PageColumnCollections',
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_PageBubbleGroupCollections = _resolveComponent("PageBubbleGroupCollections");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0
      }, {
        title: _withCtx(() => [_createTextVNode(" Collections ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_PageBubbleGroupCollections, {
          item: _unref(item)
        }, null, 8, ["item"])]),
        _: 1
      }));
    };
  }
};