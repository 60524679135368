import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createBlock as _createBlock } from "vue";

import { inject, provide } from 'vue';
const _hoisted_1 = {
  class: "px-3 h-full flex items-center"
};
export default {
  __name: 'TableRowHeader',
  setup(__props) {
    const conf = inject("conf");
    const linkTo = inject("linkTo");
    provide("header", true);
    return (_ctx, _cache) => {
      const _component_TableCheckbox = _resolveComponent("TableCheckbox");
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      return _openBlock(), _createElementBlock("tr", null, [_unref(conf)?.checkboxes ? (_openBlock(), _createElementBlock("th", {
        key: 0,
        scope: "col",
        class: "w-10 hover:cursor-pointer",
        onClick: _cache[0] || (_cache[0] = $event => _unref(conf).toggleAllChecks(_unref(conf).rows))
      }, [_createElementVNode("span", _hoisted_1, [_createVNode(_component_TableCheckbox, {
        active: _unref(conf).allChecked(_unref(conf).rows)
      }, null, 8, ["active"])])])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _unref(linkTo) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 1,
        class: "w-1/8 xl:w-1/6 2xl:w-1/4"
      })) : _createCommentVNode("", true), _unref(linkTo) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 2,
        class: "w-12"
      })) : _createCommentVNode("", true)]);
    };
  }
};