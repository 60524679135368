import { useModel as _useModel } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";

import { onMounted } from 'vue';
const _hoisted_1 = {
  class: "flex h-[38px] items-center justify-center space-x-1 rounded-md border border-gray-200 bg-gray-100 p-0.5"
};
const _hoisted_2 = ["onClick"];
export default {
  __name: 'BaseTabs',
  props: {
    "selected": {
      type: [Object, String, Number, null],
      default: () => null
    },
    "selectedModifiers": {},
    "tabs": {
      type: Array,
      required: true
    },
    "tabsModifiers": {}
  },
  emits: ["update:selected", "update:tabs"],
  setup(__props) {
    const selected = _useModel(__props, "selected");
    const tabs = _useModel(__props, "tabs");
    onMounted(() => {
      if (selected?.value !== null) {
        const index = tabs.value.findIndex(tab => tab.value === selected.value);
        if (index < 0) {
          setActive(0);
        } else {
          setActive(index);
        }
      }
    });
    function setActive(index) {
      tabs.value.forEach(tab => tab.active = false);
      tabs.value[index].active = true;
      selected.value = tabs.value[index].value;
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab, index) => {
        return _openBlock(), _createElementBlock("button", {
          key: tab,
          type: "button",
          class: _normalizeClass(["h-full min-w-[60px] border rounded-md px-3 py-1 text-sm font-medium flex-none", {
            'border-gray-200 bg-white text-gray-700': tab.active,
            'text-gray-400 hover:text-gray-700 border-gray-100': !tab.active
          }]),
          onClick: $event => setActive(index)
        }, _toDisplayString(tab.title), 11, _hoisted_2);
      }), 128))]);
    };
  }
};