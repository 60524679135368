import { api } from "@/api"

export async function listTags(libraryId) {
	const res = await api.get(`/api/libraries/${libraryId}/tags/`)

	if (res?.data) {
		res.data.forEach(tag => {
			tag.builtin = builtinTags.includes(tag.name)
		})
		return res.data 
	} else {
		return
	}
}

const builtinTags = ["Lemons", "New Growth", "Wallflowers", "Declining", "Stars", "Sloths"]
