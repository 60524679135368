import { round } from "@/utils"
import { standardDeviation, mean, sum } from "simple-statistics"
import { useLibraryStore } from "@/stores"

export function scorePages(pages) {
	const libraryStore = useLibraryStore()
	
	if (Array.isArray(pages)) {
		return pages.map(page => scorePage(page, libraryStore.maxViewedPage))
	} else if (typeof pages == "object") {
		return scorePage(pages, libraryStore.maxViewedPage)
	}
}

function scorePage(page, maxViewedPage) {
	maxViewedPage = maxViewedPage || 10000

	page.calcs = {
		scale: getScale(page, maxViewedPage),
		engagement: getEngagement(page),
		positivity: getPositivity(page),
		consistency: getConsistency(page),
	}

	if (page.metrics) {
		page.calcs = page.metrics
	}

	page.calcs.score = getScore(page.calcs)

	page.flags = {
		updateContent: getDeclining(page),
		increaseEngagement: getSloth(page),
		repurpose: getLemon(page),
		titleLengthIncorrect: page.meta.title.length >= 70,
		titleIsMissing: page.meta.title == "(Not Set)",
	}

	if (page.flags.repurpose) {
		page.flags.titleIsMissing = false
		page.flags.titleLengthIncorrect = false
		page.flags.titleIsDuplicate = false
		page.flags.updateContent = false
		page.flags.increaseEngagement = false
	}

	page.collections = {
		isLemon: isTag(page, "Lemons"),
		isStar: isTag(page, "Stars"),
		isSloth: isTag(page, "Sloths"),
		isWallflower: isTag(page, "Wallflowers"),
		isDeclining: isTag(page, "Declining"),
		isNewGrowth: isTag(page, "New Growth"),
		isMissing:
      (page.meta.title.toLowerCase().includes("404") ||
        page.meta.title.toLowerCase().includes("page not found")),
	}

	if (page.collections.isNewGrowth) page.collections.isDeclining = false

	page.calcs.opportunity = getOpportunity(page)
	page.calcs.opportunityGrowth = page.calcs.opportunity - page.calcs.score
	page.calcs.opportunityGrowthMetric = theoreticalGrowth(page, maxViewedPage)

	return page
}

function isTag(page, tagName) {
	if (!page?.tags?.length) return
	const found = page.tags.find(tag => tag.name === tagName)
	return !!found
}


function theoreticalGrowth(page, maxViewedPage) {
	const scoreChange = page.calcs.opportunityGrowth
	if (scoreChange === 0) return 0
	const currentPage = page.org12Month.pageviews
	const averagePage = (maxViewedPage + currentPage) / 2
	const yearGrowth = (averagePage * scoreChange * 0.8) + (10000 * scoreChange * 0.2)
	const smallPageModifier = .5 + (Math.min(currentPage / 30, 1) * 0.5)
	// add a 'website size' modifier in here as well
	const modifiedGrowth = Math.max(Math.round(yearGrowth * smallPageModifier), 5)
	return Math.round(modifiedGrowth / 12)
}

function getOpportunity(page) {
	let newCalcs = { ...page.calcs }
	const viewThreshold = 4

	if (page.flags.titleIsDuplicate && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.titleLengthIncorrect && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.titleIsMissing && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.updateContent) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.06, 1)
		newCalcs.positivity = Math.min(page.calcs.positivity + 0.05, 1)
		newCalcs.engagement = Math.min(page.calcs.engagement + 0.05, 1)
		newCalcs.consistency = Math.min(page.calcs.consistency + 0.05, 1)
	}
	if (page.flags.increaseEngagement) {
		newCalcs.engagement = Math.min(page.calcs.engagement + 0.15, 1)
	}
	if (page.flags.repurpose) {
		// theoretical slightly below average page on a site, should calculate this
		newCalcs.scale = Math.min(page.calcs.scale * 3, .11)
		newCalcs.consistency = Math.min(page.calcs.consistency * 3, .22)
		newCalcs.positivity = Math.min(page.calcs.positivity * 3, .16)
		newCalcs.engagement = Math.min(page.calcs.engagement * 3, .15)
	}
	if (page.calcs.score == getScore(newCalcs)) {
		return page.calcs.score
	} else {
		return Math.min(getScore(newCalcs) * (1.02 + page.calcs.scale * 0.07), 1)
	}
}

function posCalc(compare, recent) {
	const recentAvg = mean(recent)
	const compareAvg = mean(compare)
	const denom = recentAvg + compareAvg
	return denom == 0 ? 0 : recentAvg / denom
}

function getPositivity(page) {
	if (page.org12Month.pageviews == 0 || !page.orgMonthly) return 0

	const chunks = [
		page.orgMonthly.slice(0, 3),
		page.orgMonthly.slice(3, 6),
		page.orgMonthly.slice(6, 9),
		page.orgMonthly.slice(-3),
	]
	const factors = chunks.map((chunk) => Math.min((sum(chunk) / 20) * 0.9 + 0.1, 1))

	const recencyFactor = (1 - Math.min(page.daysAlive / 365, 1)) * 0.2 + 0.8
	const early = posCalc(chunks[0], chunks[1]) * factors[1]
	const middle = posCalc(chunks[1], chunks[2]) * factors[2]
	const late = posCalc(chunks[2], chunks[3]) * factors[3]
	let total = early * 0.2 + middle * 0.35 + late * 0.45
	const positivity = Math.min(1.1 * total, 1) * recencyFactor
	return round(positivity)
}

function getConsistency(page) {
	if (page.org12Month.pageviews == 0 || !page.orgMonthly) return 0

	const sizeFactor = Math.min((page.org12Month.pageviews / 20) * 0.5 + 0.5, 1)
	const recencyFactor = (1 - Math.min(page.daysAlive / 365, 1)) * 0.4 + 0.6
	const allAvg = mean(page.orgMonthly) == 0 ? 0.01 : mean(page.orgMonthly)
	const volatility = (standardDeviation(page.orgMonthly) / allAvg) * 0.30151134 * recencyFactor
	const consistency = Math.min(Math.pow(1 - volatility, 2) * sizeFactor, 1)
	return round(consistency)
}

function getScale(page, maxViewedPage) {
	if (page.org12Month.pageviews == 0) return 0
	const upv = page.org12Month.pageviews - 1 // subtracting the theoretical 'minimum' viewed page (1)
	const maxUpv = Math.max(maxViewedPage - 1, 1000) // subtracting the theoretical 'minimum' viewed page (1)
	const scale =
    ((upv / maxUpv + 0.01) ** (1 / 1000) - 0.995405417351527) *
    // eslint-disable-next-line no-loss-of-precision
    217.647624715674213
	return Math.min(round(scale), 1)
}

function getEngagement(page) {
	const shortEngagement = page.org6Month.pageviews === 0 ? 0 : 1 - page.org6Month.bounceRate
	const longEngagement = page.org12Month.pageviews === 0 ? 0 : 1 - page.org12Month.bounceRate
	const engagement = 0.6 * shortEngagement + 0.4 * longEngagement
	return round(engagement)
}

function getScore(calcs) {
	const scale = calcs.scale
	const positivity = calcs.positivity
	const consistency = calcs.consistency
	const engagement = calcs.engagement

	const scaleWeight = 0.22
	const positivityWeight = 0.29
	const consistencyWeight = 0.25
	const engagementWeight = 0.24

	const score = Math.min(1.06 *
      (scale * scaleWeight +
        positivity * positivityWeight +
        consistency * consistencyWeight +
        engagement * engagementWeight), 1)

	// theoretical best existing web page rank
	// const score = 1 * 0.33 + 0.9 * 0.24 + 0.9 * 0.21 + 0.98 * 0.22

	return round(score)
}

// eslint-disable-next-line no-unused-vars
function getStar(page) {
	return (
		page.calcs.scale * page.calcs.engagement > 0.09 &&
    page.org3Month.pageviews > 25 &&
    page.calcs.scale > 0.21 &&
    page.calcs.consistency > 0.3 &&
    page.calcs.engagement > 0.55 &&
    page.calcs.positivity > 0.4 &&
    !getWallflower(page) && 
    !getSloth(page)
	)
}

function getDeclining(page) {
	return (
		(page.org3Month.growthPercent < -0.15 
		|| page.calcs.positivity < 0.40
		|| (page.calcs.positivity < 0.43 && page.calcs.consistency > 0.35 && page.calcs.scale > 0.7)
		) &&
    page.calcs.scale * page.calcs.engagement > 0.09 &&
    page.org6Month.pageviews > 40 &&
    page.calcs.consistency > 0.3
	)
}

function getSloth(page) {
	return (
		page.org3Month.pageviews > 15 &&
    page.calcs.engagement < 0.55
	)
}

function getWallflower(page) {
	return (
		page.calcs.scale < 0.3 &&
    page.org3Month.pageviews < 200 &&
    page.org3Month.pageviews > 15 &&
    page.calcs.engagement >= 0.82
	)
}

function getLemon(page) {
	return (
		page.calcs.scale < 0.25 && 
		page.daysAlive > 90 && 
		page.org12Month.pageviews > 4 &&
		page.org3Month.pageviews < 15
	)
}

// eslint-disable-next-line no-unused-vars
function getNewGrowth(page) {
	return (
		page.daysAlive < 60 && 
    page.org3Month.pageviews > 3
	)
}