import { api } from "@/api"

export async function postTag(libraryId, options) {
	const body = {
		"name": options.name,
		"page_ids": options.page_ids || undefined,
		"page_query": options.page_query || undefined,
		"description": "",
		"icon": "",
	}
	const res = await api.post(`/api/libraries/${libraryId}/tags/`, body)
	return res?.data
}
