import { useModel as _useModel } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "flex items-center justify-between gap-x-2"
};
import { numDisplay } from "@/utils";

import { useLocalStore, useLibraryStore } from '@/stores';
export default {
  __name: 'TopicExplorerTablePages',
  props: {
    "conf": {
      type: Object,
      required: true
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const {
      pageTableSettings: settings
    } = useLocalStore();
    const libraryStore = useLibraryStore();
    const conf = _useModel(__props, "conf");
    return (_ctx, _cache) => {
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_TableMenuGroupCopy = _resolveComponent("TableMenuGroupCopy");
      const _component_TableMenuItemPageKey = _resolveComponent("TableMenuItemPageKey");
      const _component_TableMenuGroup = _resolveComponent("TableMenuGroup");
      const _component_TableMenu = _resolveComponent("TableMenu");
      const _component_PageColumnTitleOrUrl = _resolveComponent("PageColumnTitleOrUrl");
      const _component_TableColumn = _resolveComponent("TableColumn");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: conf.value,
        "onUpdate:conf": _cache[0] || (_cache[0] = $event => conf.value = $event),
        "link-to": "PageSingle",
        target: "_blank"
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_TableNavCopy, {
          class: "ml-auto",
          items: conf.value.getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_TableMenu, null, {
          default: _withCtx(() => [_createVNode(_component_TableMenuGroupCopy), _createVNode(_component_TableMenuGroup, null, {
            default: _withCtx(() => [_createVNode(_component_TableMenuItemPageKey)]),
            _: 1
          })]),
          _: 1
        })])]),
        columns: _withCtx(({
          item
        }) => [_createVNode(_component_PageColumnTitleOrUrl, {
          class: _normalizeClass({
            'w-full': !_unref(libraryStore).gscSiteurl,
            'w-[60%]': _unref(libraryStore).gscSiteurl
          }),
          "title-url-toggle": _unref(settings).titleUrlToggle
        }, null, 8, ["class", "title-url-toggle"]), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_TableColumn, {
          key: 0,
          class: "w-[105px]",
          "sort-by": "searchData.clicks"
        }, {
          title: _withCtx(() => [_createTextVNode(" Clicks ")]),
          tooltip: _withCtx(() => [_createTextVNode(" The number of clicks each page received for topic keywords in the last month. Source: GSC ")]),
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(numDisplay)(item?.searchData?.clicks, 'm')), 1)]),
          _: 2
        }, 1024)) : _createCommentVNode("", true), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_TableColumn, {
          key: 1,
          class: "w-[105px]",
          "sort-by": "searchData.impressions"
        }, {
          title: _withCtx(() => [_createTextVNode(" Impr. ")]),
          tooltip: _withCtx(() => [_createTextVNode(" The number of impressions each page received for topic keywords in the last month. Source: GSC ")]),
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(numDisplay)(item?.searchData?.impressions, 'm')), 1)]),
          _: 2
        }, 1024)) : _createCommentVNode("", true), _createVNode(_component_TableColumn, {
          class: "w-[105px]",
          "sort-by": "matchScore"
        }, {
          title: _withCtx(() => [_createTextVNode(" Match % ")]),
          tooltip: _withCtx(() => [_createTextVNode(" An estimate of how well each page matches the search topic. ")]),
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(numDisplay)(item?.matchScore / 100, '%s')), 1)]),
          _: 2
        }, 1024)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 3
      }, 8, ["conf"]);
    };
  }
};