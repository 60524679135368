import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = {
  key: 0,
  class: "pl-2"
};
export default {
  __name: 'ClientDashboardColumnExpected',
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const expected = computed(() => item ? item.expected : false);
    const due = computed(() => item ? item.due : null);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0
      }, {
        title: _withCtx(() => [_createTextVNode(" Expected ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1
      }, {
        default: _withCtx(() => [_unref(expected) && _unref(due) ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.dayjs(_unref(item).due).format('MM/DD')), 1)) : _createCommentVNode("", true)]),
        _: 1
      }));
    };
  }
};