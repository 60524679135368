import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";

import { inject } from 'vue';
export default {
  __name: 'PageTableNavResetFilters',
  setup(__props) {
    const conf = inject("conf");
    return (_ctx, _cache) => {
      const _component_hero_x_mark = _resolveComponent("hero-x-mark");
      const _component_BaseButton = _resolveComponent("BaseButton");
      return _unref(conf).filterCount > 0 || _unref(conf).lastSearched !== null && _unref(conf).lastSearched !== '' ? (_openBlock(), _createBlock(_component_BaseButton, {
        key: 0,
        styles: "inset",
        onClick: _cache[0] || (_cache[0] = $event => _unref(conf).clearFilters())
      }, {
        default: _withCtx(() => [_createTextVNode(" Reset"), _createVNode(_component_hero_x_mark, {
          class: "ml-2 h-4 w-4"
        })]),
        _: 1
      })) : _createCommentVNode("", true);
    };
  }
};