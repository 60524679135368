import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";

import { useRoute, useRouter } from 'vue-router';
import { useUserStore, useLibraryFormStore } from '@/stores';
import { onMounted } from 'vue';
const _hoisted_1 = {
  class: "w-full h-full flex fixed inset-0 bg-white z-50"
};
const _hoisted_2 = {
  class: "h-full relative overflow-scroll w-1/2 border-r py-24"
};
const _hoisted_3 = {
  class: "w-full h-full min-h-[600px] max-w-[740px] px-8 mx-auto flex flex-col relative"
};
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center mx-auto md:absolute md:-top-[40px] px-8 md:right-0"
};
const _hoisted_5 = {
  class: "w-1/2 py-24 overflow-scroll bg-[#f6f2ed]"
};
const _hoisted_6 = {
  class: "w-full h-full min-h-[600px] max-w-[740px] px-8 mx-auto flex flex-col relative"
};
export default {
  __name: 'CreateLibraryLayout',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const libraryForm = useLibraryFormStore();
    onMounted(async () => {
      libraryForm.resetForm();
      if (route.name !== "CreateLibraryName" && !libraryForm.libraryName) {
        router.push({
          name: "CreateLibraryName",
          params: {
            workspaceId: route.params.workspaceId
          }
        });
      }
      userStore.populateGaProperties();
      userStore.populateGscProperties();
      userStore.getWorkspaceLibraries();
      document.title = "ércule – library creation";
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_BaseButton = _resolveComponent("BaseButton");
      const _component_CreateLibraryPanelDetails = _resolveComponent("CreateLibraryPanelDetails");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_router_view), _unref(userStore).fallbackLibrary ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_BaseButton, {
        to: {
          name: 'LibraryOverview',
          params: {
            libraryId: _unref(userStore)?.fallbackLibrary?.id,
            workspaceId: _unref(userStore)?.fallbackLibrary?.workspace
          }
        },
        size: "md"
      }, {
        default: _withCtx(() => [_createTextVNode(" Cancel ")]),
        _: 1
      }, 8, ["to"])])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_CreateLibraryPanelDetails)])])]);
    };
  }
};