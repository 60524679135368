import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref } from "vue";

import { computed } from 'vue';
const _hoisted_1 = ["title"];
export default {
  __name: 'BaseBubbleGroup',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: "md"
    }
  },
  setup(__props) {
    const props = __props;
    const title = computed(() => {
      if (props.items?.length > 0) {
        return props.items.reduce((sum, current) => sum += current.text + " • ", "• ");
      } else {
        return "";
      }
    });
    return (_ctx, _cache) => {
      const _component_BaseBubble = _resolveComponent("BaseBubble");
      return _openBlock(), _createElementBlock("div", {
        class: "relative overflow-hidden flex",
        title: _unref(title)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, bubble => {
        return _openBlock(), _createBlock(_component_BaseBubble, {
          key: bubble,
          class: "mr-2 my-1",
          to: bubble.to,
          href: bubble.href,
          size: __props.size
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(bubble.text), 1)]),
          _: 2
        }, 1032, ["to", "href", "size"]);
      }), 128))], 8, _hoisted_1);
    };
  }
};