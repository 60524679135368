import { createRouter, createWebHistory } from "vue-router"
import { authCheck } from "@/utils/authCheck"

import AppPrivacyPolicy from "@/views/AppPrivacyPolicy.vue"
import AppTermsOfUse from "@/views/AppTermsOfUse.vue"
import AppPageNotFound from "@/views/AppPageNotFound.vue"
import AppLogin from "@/views/AppLogin.vue"
import AppOpenInvitations from "@/views/AppOpenInvitations.vue"

import WorkspaceCreate from "@/views/WorkspaceCreate.vue"
import WorkspaceManageUsers from "@/views/WorkspaceManageUsers.vue"

import CreateLibraryLayout from "@/layouts/CreateLibraryLayout.vue"
import CreateLibraryName from "@/views/CreateLibraryName.vue"
import CreateLibraryAnalytics from "@/views/CreateLibraryAnalytics.vue"
import CreateLibrarySearch from "@/views/CreateLibrarySearch.vue"
import CreateLibraryConnect from "@/views/CreateLibraryConnect.vue"
import CreateLibraryFilters from "@/views/CreateLibraryFilters.vue"
import CreateLibraryReview from "@/views/CreateLibraryReview.vue"

import LibraryLayout from "@/layouts/LibraryLayout.vue"
import LibraryOverview from "@/views/LibraryOverview.vue"
import LibraryPages from "@/views/LibraryPages.vue"
import LibraryAnalysis from "@/views/LibraryAnalysis.vue"
import LibraryExporter from "@/views/LibraryExporter.vue"
import PageSingle from "@/views/PageSingle.vue"

import TopicExplorer from "@/views/TopicExplorer.vue"
import TopicReports from "@/views/TopicReports.vue"
import TopicSingle from "@/views/TopicSingle.vue"

import SettingsLayout from "@/layouts/SettingsLayout.vue"
import SettingsAccountGeneral from "@/views/SettingsAccountGeneral.vue"
import SettingsWorkspaceGeneral from "@/views/SettingsWorkspaceGeneral.vue"
import SettingsWorkspaceManageUsers from "@/views/SettingsWorkspaceManageUsers.vue"
import SettingsWorkspaceSubscription from "@/views/SettingsWorkspaceSubscription.vue"
import SettingsLibraryGeneral from "@/views/SettingsLibraryGeneral.vue"
import SettingsLibrarySyncStatus from "@/views/SettingsLibrarySyncStatus.vue"

import CollectionsCreate from "@/views/CollectionsCreate.vue"
import CollectionsActionItems from "@/views/CollectionsActionItems.vue"
import CollectionsStars from "@/views/CollectionsStars.vue"
import CollectionsDeclining from "@/views/CollectionsDeclining.vue"
import CollectionsSloths from "@/views/CollectionsSloths.vue"
import CollectionsWallflowers from "@/views/CollectionsWallflowers.vue"
import CollectionsLemons from "@/views/CollectionsLemons.vue"
import CollectionsNew from "@/views/CollectionsNew.vue"
import CollectionRefinement from "@/views/CollectionRefinement.vue"

import TagSingle from "@/views/TagSingle.vue"

import ClientDashboard from "@/views/ClientDashboard.vue"

export const routes = [
	{
		path: "/",
		name: "Home",
	},
	{
		path: "/libraries",
		name: "LibraryList",
		redirect: { name: "Home" } 
	},
	{
		path: "/privacy-policy",
		component: AppPrivacyPolicy,
	},
	{
		path: "/terms-of-use",
		component: AppTermsOfUse,
	},
	{
		path: "/login",
		name: "AppLogin",
		component: AppLogin,
	},
	{
		path: "/join",
		name: "AppJoin",
		component: AppLogin,
	},
	{
		path: "/open-invitations",
		name: "AppOpenInvitations",
		component: AppOpenInvitations,
		meta: { authRequired: true },
	},
	{
		path: "/create-workspace",
		name: "WorkspaceCreate",
		component: WorkspaceCreate,
		meta: { authRequired: true },
	},
	{
		path: "/workspace/:workspaceId/users",
		name: "WorkspaceManageUsers",
		component: WorkspaceManageUsers,
		meta: { authRequired: true },
	},
	{
		path: "/workspace/:workspaceId/",
		component: CreateLibraryLayout,
		meta: { authRequired: true },
		children: [
			{ 
				path: "", 
				name: "WorkspaceHome"
			},
			{
				path: "create-library/name",
				name: "CreateLibraryName",
				component: CreateLibraryName,
				meta: { authRequired: true },
			},
			{
				path: "create-library/analytics",
				name: "CreateLibraryAnalytics",
				component: CreateLibraryAnalytics,
				meta: { authRequired: true },
			},
			{
				path: "create-library/search",
				name: "CreateLibrarySearch",
				component: CreateLibrarySearch,
				meta: { authRequired: true },
			},
			{
				path: "create-library/connect",
				name: "CreateLibraryConnect",
				component: CreateLibraryConnect,
				meta: { authRequired: true },
			},
			{
				path: "create-library/filters",
				name: "CreateLibraryFilters",
				component: CreateLibraryFilters,
				meta: { authRequired: true },
			},
			{
				path: "create-library/review",
				name: "CreateLibraryReview",
				component: CreateLibraryReview,
				meta: { authRequired: true },
			},
		]
	},
	{
		path: "/collection-refinement",
		name: "CollectionRefinement",
		component: CollectionRefinement,
		meta: { authRequired: true },
	},
	{
		path: "/workspace/:workspaceId/libraries/:libraryId",
		component: LibraryLayout,
		meta: { authRequired: true },
		children: [
			{ 
				path: "", 
				redirect: { name: "LibraryOverview" } 
			},
			{ 
				path: "overview", 
				name: "LibraryOverview", 
				component: LibraryOverview,
				meta: { authRequired: true },
			},
			{ 
				path: "client-dashboard", 
				name: "ClientDashboard", 
				component: ClientDashboard,
				meta: { authRequired: true },
			},
			{
				path: "pages",
				name: "LibraryPages",
				component: LibraryPages,
				meta: { authRequired: true },
			},
			{
				path: "topic-explorer",
				name: "TopicExplorer",
				component: TopicExplorer,
				meta: { authRequired: true },
			},
			{
				path: "topics",
				name: "TopicReports",
				component: TopicReports,
				meta: { authRequired: true },
			},
			{
				path: "topics/:topicSlug",
				name: "TopicSingle",
				component: TopicSingle,
				meta: { authRequired: true },
			},
			{ 
				path: "action-items", 
				name: "CollectionsActionItems", 
				component: CollectionsActionItems,
				meta: { authRequired: true },
			},
			{
				path: "analysis",
				name: "LibraryAnalysis",
				component: LibraryAnalysis,
				meta: { authRequired: true },
			},
			{
				path: "exporter",
				name: "LibraryExporter",
				component: LibraryExporter,
				meta: { authRequired: true },
			},
			{
				path: "collections/:tagId",
				name: "TagSingle",
				component: TagSingle,
				meta: { authRequired: true },
			},
			{
				path: "collections/create",
				name: "CollectionsCreate",
				component: CollectionsCreate,
				meta: { authRequired: true },
			},
			{
				path: "collections/declining",
				name: "CollectionsDeclining",
				component: CollectionsDeclining,
				meta: { authRequired: true },
			},
			{
				path: "collections/stars",
				name: "CollectionsStars",
				component: CollectionsStars,
				meta: { authRequired: true },
			},
			{
				path: "collections/sloths",
				name: "CollectionsSloths",
				component: CollectionsSloths,
				meta: { authRequired: true },
			},
			{
				path: "collections/wallflowers",
				name: "CollectionsWallflowers",
				component: CollectionsWallflowers,
				meta: { authRequired: true },
			},
			{
				path: "collections/lemons",
				name: "CollectionsLemons",
				component: CollectionsLemons,
				meta: { authRequired: true },
			},
			{
				path: "collections/new",
				name: "CollectionsNew",
				component: CollectionsNew,
				meta: { authRequired: true },
			},
			{
				path: "page/:pageId",
				name: "PageSingle",
				component: PageSingle,
				meta: { authRequired: true },
			},
			{
				path: "settings",
				component: SettingsLayout,
				meta: { authRequired: true },
				children: [
					{ 
						path: "", 
						redirect: { name: "SettingsLibraryGeneral" } 
					},
					{ 
						path: "workspace", 
						name: "SettingsWorkspaceGeneral", 
						component: SettingsWorkspaceGeneral,
						meta: { authRequired: true },
					},
					{ 
						path: "workspace/manage-users", 
						name: "SettingsWorkspaceManageUsers", 
						component: SettingsWorkspaceManageUsers,
						meta: { authRequired: true },
					},
					{ 
						path: "workspace/subscription", 
						name: "SettingsWorkspaceSubscription", 
						component: SettingsWorkspaceSubscription,
						meta: { authRequired: true },
					},
					{ 
						path: "library", 
						name: "SettingsLibraryGeneral", 
						component: SettingsLibraryGeneral,
						meta: { authRequired: true },
					},
					{ 
						path: "library/sync-status", 
						name: "SettingsLibrarySyncStatus", 
						component: SettingsLibrarySyncStatus,
						meta: { authRequired: true },
					},
					{ 
						path: "account", 
						name: "SettingsAccountGeneral", 
						component: SettingsAccountGeneral,
						meta: { authRequired: true },
					},
				],
			},
		],
	},
	{ 
		path: "/ottimo", 
		redirect: { name: "Home" } 
	},
	{
		path: "/:pathMatch(.*)",
		component: AppPageNotFound,
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash }
		} else if (savedPosition) {
			return savedPosition
		} else if (to.path != from.path) {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach(authCheck)

export default router
