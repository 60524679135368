import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";

import { inject, ref, computed } from 'vue';
import { copyItemsToClipboard } from '@/utils';
const _hoisted_1 = {
  class: "flex items-center"
};
export default {
  __name: 'TableNavCopy',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    format: {
      type: String,
      default: "raw"
    }
  },
  setup(__props) {
    const conf = inject("conf");
    const props = __props;
    const itemsCopied = ref(false);
    const itemsCopiedLength = ref(0);
    const itemsCopiedTimeout = ref(null);
    const compFormat = computed(() => conf?.value?.copyFormat || props.format);
    function copy() {
      copyItemsToClipboard(props.items, compFormat.value);
      clearTimeout(itemsCopiedTimeout.value);
      itemsCopiedLength.value = props.items?.length || 0;
      itemsCopied.value = true;
      itemsCopiedTimeout.value = setTimeout(() => {
        itemsCopied.value = false;
      }, 2000);
    }
    return (_ctx, _cache) => {
      const _component_hero_clipboard_document_check = _resolveComponent("hero-clipboard-document-check");
      const _component_hero_clipboard_document = _resolveComponent("hero-clipboard-document");
      const _component_BaseTooltip = _resolveComponent("BaseTooltip");
      const _component_BaseButton = _resolveComponent("BaseButton");
      return _openBlock(), _createBlock(_component_BaseButton, {
        size: "mdSquare",
        class: "relative flex-none",
        onClick: copy
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_unref(itemsCopied) ? (_openBlock(), _createBlock(_component_hero_clipboard_document_check, {
          key: 0,
          class: "w-5 h-5 text-green-500"
        })) : (_openBlock(), _createBlock(_component_hero_clipboard_document, {
          key: 1,
          class: "w-5 h-5"
        }))]), _createVNode(_component_BaseTooltip, {
          visibility: _unref(itemsCopied),
          delay: "xs",
          class: "bottom-full -right-[20px]",
          size: "auto"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(itemsCopiedLength)) + " selected items copied. ", 1)]),
          _: 1
        }, 8, ["visibility"])]),
        _: 1
      });
    };
  }
};