import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
const _hoisted_1 = ["href"];
const _hoisted_2 = ["disabled"];
export default {
  __name: 'BaseButton',
  props: {
    to: Object,
    href: String,
    size: {
      type: String,
      default: "md"
    },
    styles: {
      type: String,
      default: "secondary"
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const props = __props;
    const sizes = {
      lg: "px-10 py-4 text-base ",
      md: "px-4 py-2 text-sm ",
      mdSquare: "p-2 text-sm ",
      sm: "px-3 py-1 text-sm ",
      smSquare: "p-1 text-sm ",
      xs: "px-3 py-1 text-xs "
    };
    const styleMap = {
      primary: "shadow-sm text-white bg-blue-500 hover:bg-blue-400 ",
      secondary: "shadow-sm bg-white hover:bg-gray-50 border border-solid border-zinc-200 ",
      inset: "hover:bg-gray-50 ",
      delete: "shadow-sm text-red-500 bg-white hover:bg-slate-100 border border-solid border-red-500 ",
      back: "bg-gray-100 hover:bg-gray-200 ",
      none: "hover:text-gray-500 "
    };
    const classString = computed(() => {
      let str = !props.enabled ? "opacity-40 pointer-events-none " : "";
      str += "hover:cursor-pointer inline-flex items-center justify-center font-medium rounded-md select-none ";
      str += sizes[props.size];
      str += styleMap[props.styles];
      return str;
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return __props.to ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: __props.to,
        class: _normalizeClass([_unref(classString)])
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["to", "class"])) : __props.href ? (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: __props.href,
        target: "_blank",
        class: _normalizeClass([_unref(classString)])
      }, [_renderSlot(_ctx.$slots, "default")], 10, _hoisted_1)) : (_openBlock(), _createElementBlock("button", {
        key: 2,
        class: _normalizeClass([_unref(classString)]),
        disabled: !__props.enabled
      }, [_renderSlot(_ctx.$slots, "default")], 10, _hoisted_2));
    };
  }
};