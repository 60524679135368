import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { useLocalStore } from '@/stores';
export default {
  __name: 'TableMenuItemPageKey',
  setup(__props) {
    const {
      pageTableSettings
    } = useLocalStore();
    return (_ctx, _cache) => {
      const _component_TableMenuToggleTabs = _resolveComponent("TableMenuToggleTabs");
      return _openBlock(), _createBlock(_component_TableMenuToggleTabs, {
        toggle: _unref(pageTableSettings).titleUrlToggle,
        "onUpdate:toggle": _cache[0] || (_cache[0] = $event => _unref(pageTableSettings).titleUrlToggle = $event)
      }, {
        tabOne: _withCtx(() => [_createTextVNode(" URL ")]),
        tabTwo: _withCtx(() => [_createTextVNode(" Title ")]),
        default: _withCtx(() => [_createTextVNode(" Page key ")]),
        _: 1
      }, 8, ["toggle"]);
    };
  }
};