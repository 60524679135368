import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_TableMenuGroupCopy = _resolveComponent("TableMenuGroupCopy");
  const _component_TableMenuItemCompactView = _resolveComponent("TableMenuItemCompactView");
  const _component_TableMenuItemPageKey = _resolveComponent("TableMenuItemPageKey");
  const _component_TableMenuGroup = _resolveComponent("TableMenuGroup");
  const _component_TableMenu = _resolveComponent("TableMenu");
  return _openBlock(), _createBlock(_component_TableMenu, null, {
    default: _withCtx(() => [_createVNode(_component_TableMenuGroupCopy), _createVNode(_component_TableMenuGroup, null, {
      default: _withCtx(() => [_createVNode(_component_TableMenuItemCompactView), _createVNode(_component_TableMenuItemPageKey)]),
      _: 1
    })]),
    _: 1
  });
}