import "core-js/modules/es.array.to-sorted.js";
import { PageFilterConfig } from "@/utils/pageFilters";
export class TableConfig {
  constructor(options) {
    this.rows = [];
    this.totalItems = undefined;
    this.syncing = true;
    this.getAsync = options.getAsync || null;
    this.key = options.key || "id";
    this.by = options.by || getSortString(options.sortGroup, options.sortField);
    this.dir = options.dir || "DESC";
    this.pageNumber = options.pageNumber || 1;
    this.pageSize = options.pageSize || 100;
    this.sortField = options.sortField || "page_views";
    this.sortGroup = options.sortGroup || undefined;
    this.searchText = options.searchText;
    this.lastSearched = null;
    this.checkboxes = options.checkboxes || false;
    this.checked = {};
    this.copyFormat = options.copyFormat || "raw";
  }
  async get(settings = {}) {
    this.syncing = !settings.toExport;
    this.lastSearched = settings.toExport ? this.lastSearched : this.searchText;
    let res = await this.getItems(settings);
    if (settings.toExport) {
      return res?.rows || undefined;
    } else {
      this.rows = res?.rows || [];
      this.totalItems = res?.total || 0;
    }
    this.syncing = false;
  }
  async getItems(settings) {
    return await this.getAsync(settings);
  }
  clearFilters() {
    this.searchText = "";
    this.get();
  }
  setSort(sortField, sortGroup, reverse = false) {
    const sortString = getSortString(sortGroup, sortField);
    if (sortString === this.by) {
      if (this.dir === "ASC") this.dir = "DESC";else if (this.dir === "DESC") this.dir = "ASC";
    } else {
      this.by = sortString;
      this.sortField = sortField;
      this.sortGroup = sortGroup;
      this.dir = reverse ? "ASC" : "DESC";
    }
    this.setPage(1);
  }
  setPage(pn) {
    this.pageNumber = pn;
  }
  getCheckedItems() {
    const filteredItems = Object.keys(this.checked).map(key => this.checked[key]);
    return sortTableItems(filteredItems, this.by, this.dir);
  }
  toggleCheck(item) {
    if (this.checked[item[this.key]]) {
      delete this.checked[item[this.key]];
    } else {
      this.checked[item[this.key]] = item;
    }
  }
  toggleAllChecks(items) {
    if (this.allChecked(items)) {
      items.forEach(item => {
        delete this.checked[item[this.key]];
      });
    } else {
      items.forEach(item => {
        this.checked[item[this.key]] = item;
      });
    }
  }
  clearAllChecks() {
    this.checked = {};
  }
  isChecked(item) {
    if (!item) return;
    return !!this.checked[item[this.key]];
  }
  allChecked(items) {
    if (!items?.length > 0) return;
    let anyUnchecked = false;
    items.every(item => {
      if (!this.checked[item[this.key]]) {
        anyUnchecked = true;
        return false;
      } else {
        return true;
      }
    });
    return !anyUnchecked;
  }
}
export class PageTableConfig extends TableConfig {
  constructor(options) {
    super(options);
    this.filterConf = reactive(new PageFilterConfig());
    this.filterCount = 0;
  }
  clearFilters() {
    this.filterConf.clearFilters();
    this.searchText = "";
    this.get();
  }
  async getItems(settings) {
    let filters = this.filterConf.getFormatted();
    this.filterCount = this.filterConf.getNonEmptyGroupCount();
    if (this.searchText && this.searchText !== "") {
      const searchExpression = {
        "filter_expression_type": "TEXT_CONTAINS",
        "filter": {
          "search_text": removeProtocolInString(this.searchText),
          "search_fields": ["CANONICAL_PAGE_ID", "BEST_TITLE"]
        }
      };
      if (filters) {
        filters = {
          "filter_expression_type": "AND_GROUP",
          "and_group": [searchExpression, filters]
        };
      } else {
        filters = searchExpression;
      }
    }
    const data = await this.getAsync({
      ...settings,
      pn: settings.toExport ? 1 : this.pageNumber,
      ps: settings.toExport ? 5000 : this.pageSize,
      so_group: this.sortGroup,
      so_direction: this.dir,
      so_field: this.sortField,
      filter_expression: filters
    });
    return {
      rows: data?.pages || [],
      total: data?.count || 0
    };
  }
}
export class LocalTableConfig extends TableConfig {
  constructor(options) {
    super(options);
    this.localData = options.localData || null;
    this.searchField = options.searchField || null;
  }
  async getItems(settings = {}) {
    if (this.getAsync && (this.localData === null || settings.refresh)) {
      const data = await this.getAsync({
        ...settings,
        searchText: this.searchText
      });
      this.localData = data || [];
      this.setPage(1);
      this.clearAllChecks();
    }
    if (!this.localData || this.localData?.length === 0) {
      return {
        total: 0,
        rows: []
      };
    }
    let items = this.localData;
    if (this.searchField) {
      items = filterTableItems(items, this.searchField, this.searchText);
    }
    if (this.externalFilter) {
      items = this.externalFilter(items);
    }
    items = sortTableItems(items, this.by, this.dir);
    const total = items?.length;
    const sliceStart = (this.pageNumber - 1) * this.pageSize;
    const rows = settings.toExport ? items : items.slice(sliceStart, sliceStart + this.pageSize);
    return {
      rows: rows || [],
      total: total || 0
    };
  }
}
function sortTableItems(items, sortBy, sortDir) {
  if (!items) {
    return;
  } else if (!sortBy || sortBy === "") {
    return items;
  }
  const properties = sortBy.split(".");
  return items.toSorted((a, b) => {
    properties.forEach(prop => {
      a = a !== undefined ? a[prop] : undefined;
      b = b !== undefined ? b[prop] : undefined;
    });
    if (typeof a == "string" && typeof b == "string") {
      if (sortDir == "DESC") {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
      } else {
        return a.toLowerCase() > b.toLowerCase() ? -1 : 1;
      }
    } else if (a === undefined || b === undefined) {
      if (a === undefined) {
        return sortDir === "DESC" ? 1 : -1;
      } else if (b === undefined) {
        return sortDir === "DESC" ? -1 : 1;
      }
    } else {
      if (sortDir == "DESC") {
        return a > b ? -1 : 1;
      } else {
        return a > b ? 1 : -1;
      }
    }
  });
}
function filterTableItems(items, searchField, searchText) {
  if (!searchText || !searchText != "" || !searchField) return items;
  const searchString = removeProtocolInString(searchText);
  const searchProps = searchField.split(".");
  return items.filter(item => {
    searchProps.forEach(prop => {
      item = item[prop] !== undefined ? item[prop] : undefined;
    });
    if (item && item.length > 0) {
      return item.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    } else {
      return false;
    }
  });
}
function removeProtocolInString(str) {
  if (!str) return;
  if (str.indexOf("https://") === 0) {
    return str.substring(8);
  } else if (str.indexOf("http://") === 0) {
    return str.substring(7);
  } else {
    return str;
  }
}
function getSortString(sortGroup, sortField) {
  return sortGroup ? `${sortField}_${sortGroup}` : sortField;
}