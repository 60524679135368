import { TimeSeries, emptyData } from "@/utils"
import { useLibraryStore } from "@/stores"

export function processPages(pages) {
	const libraryStore = useLibraryStore()

	if (Array.isArray(pages)) {
		return pages.map(page => processPage(page, libraryStore.status?.syncDate))
	} else if (typeof pages == "object") {
		return processPage(pages, libraryStore.status?.syncDate)
	}
}

function processPage(page, syncDate) {
	page.meta = {
		title: page.best_title != null && page.best_title?.length > 0 ? page.best_title : page.canonical_page_id,
		hasBestTitle: page.best_title != null, 
		hostname: page.hostname,
		url: page.canonical_page_id, 
		cleanUrl: page.canonical_page_id, 
		combinedSearchField: page.canonical_page_id + page.best_title
	}

	if (page.meta?.url?.indexOf(page.meta.hostname) == 0) {
		page.meta.path = page.meta.url.replace(page.meta.hostname, "")
	}

	if (page.meta.path === "") {
		page.meta.path = "/"
	}

	// normalizing metrics
	page.allPageData = page.all_page_data || []
	page.orgPageData = page.org_page_data || []

	page.timeSeries = {
		all: new TimeSeries(page.allPageData, "page", syncDate),
		org: new TimeSeries(page.orgPageData, "page", syncDate)
	}

	page.allMonthly = page.timeSeries.all.monthly
	page.orgMonthly = page.timeSeries.org.monthly

	page.org1Month = page.timeSeries.org.getData ? page.timeSeries.org.getData(1, "month") : emptyData
	page.org3Month = normalizePageData(page.org_last_3_months_data)
	page.org6Month = normalizePageData(page.org_last_6_months_data)
	page.org12Month = normalizePageData(page.org_last_12_months_data)

	const org3MonthComparison = page.org6Month.pageviews - page.org3Month.pageviews
	page.org3Month.growthPercent = org3MonthComparison != 0 ? (page.org3Month.pageviews - org3MonthComparison) / org3MonthComparison : 1
	page.daysAlive = page.timeSeries?.all?.daysAlive || 0

	delete page.hostname
	return page
}

function normalizePageData(obj) {
	if (!obj) {
		return emptyData
	} else {
		return {
			pageviews: obj.page_views || 0,
			bounces: obj.bounces || 0,
			sessions: obj.sessions || 0,
			bounceRate: obj.sessions === 0 ? 0 : obj.bounces / obj.sessions 
		}
	}
}
