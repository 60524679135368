import Papa from "papaparse"
import fileDownload from "js-file-download"
import dayjs from "dayjs"
import { round } from "@/utils"

export function copyItemsToClipboard(items, config = "raw") {
	const data = config === "raw" ? items : formatJSON(items, config)
	const csvData = Papa.unparse(data, { delimiter: "\t", newline: "\n"})
	navigator.clipboard.writeText(csvData)
}

export function copyDataToClipboard(data) {
	if (typeof data === "object") {
		data = Papa.unparse(data, { delimiter: "\t", newline: "\n"})
	}
	navigator.clipboard.writeText(data)
}

export function exportItemsToCsv(items, config = "raw", title = "export.csv") {
	const data = config === "raw" ? items : formatJSON(items, config)
	const csvData = Papa.unparse(data)
	const todayFormatted = dayjs().format("YYYY-MM-DD")
	fileDownload(csvData, `${title} ${todayFormatted}.csv`)
}

function formatJSON(items, config) {
	config = typeof config == "string" ? presets[config] : config

	return items.map(item => {
		const p = {}
		config.forEach(header => {
			let data
			if (header.dataPoint === "collections") {
				data = getCollectionString(item)
			} else if (header.dataPoint === "flags") {
				data = getFlagString(item)
			} else {
				let a = item
				header.dataPoint.split(".").forEach(prop => { 
					a = a !== undefined && a[prop] !== undefined ? a[prop] : undefined 
				})
				data = typeof a == "number" ? round(a) : a 
			}
			p[header.display] = data
		})
		return p
	})
}

function getCollectionString(page) {
	let collectionString = ""
	if (page?.collections) {
		if (page.collections.isStar) collectionString += "⭐️Star,  "
		if (page.collections.isDeclining) collectionString += "📉Declining,  "
		if (page.collections.isSloth) collectionString += "🦥Sloth,  "
		if (page.collections.isWallflower) collectionString += "🌼Wallflower,  "
		if (page.collections.isLemon) collectionString += "🍋Lemon,  "
		if (page.collections.isNewGrowth) collectionString += "🎉New,  " 
		collectionString = collectionString.slice(0, -3)
	}
	return collectionString  
}

function getFlagString(page) {
	let flagString = ""
	if (page?.flags) {
		if (page.flags.updateContent) flagString += "Rewrite,  "
		if (page.flags.increaseEngagement) flagString += "Improve Engagement,  "
		if (page.flags.repurpose) flagString += "Repurpose,  "
		if (page.flags.titleIsMissing) flagString += "Fix Missing Title,  "
		if (page.flags.titleLengthIncorrect) flagString += "Shorten Title,  "
		flagString = flagString.slice(0, -3)
	}
	return flagString
}

const presets = {
	pages: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Org. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Org. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. Org. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "3 Mo. Org. Views", dataPoint: "org3Month.pageviews" },
		{ display: "3 Mo. Org. Bounce Rate", dataPoint: "org3Month.bounceRate" },
		{ display: "3 Mo. Org. % Change", dataPoint: "org3Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	ottimoExporterPages: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "ID", dataPoint: "id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Org. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Org. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. Org. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "3 Mo. Org. Views", dataPoint: "org3Month.pageviews" },
		{ display: "3 Mo. Org. Bounce Rate", dataPoint: "org3Month.bounceRate" },
		{ display: "3 Mo. Org. % Change", dataPoint: "org3Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Opportunity", dataPoint: "calcs.opportunityGrowthMetric" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	ottimoExporterTopics: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "ID", dataPoint: "id" },
		{ display: "Topic", dataPoint: "searchData.topic" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Clicks", dataPoint: "searchData.clicks" },
		{ display: "Impressions", dataPoint: "searchData.impressions" },
		{ display: "Keyword Count", dataPoint: "searchData.keywordCount" },
		{ display: "Topic Match %", dataPoint: "matchScore" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	topicExplorerKeywords: [
		{ display: "Topic", dataPoint: "search"},
		{ display: "Keyword", dataPoint: "keyword"},
		{ display: "Clicks", dataPoint: "clicks"},
		{ display: "Impressions", dataPoint: "impressions"},
		{ display: "Volume", dataPoint: "volume"},
		{ display: "Competition", dataPoint: "competition"},
		{ display: "Rank", dataPoint: "positionRounded"},
		{ display: "Top Ranking Page", dataPoint: "topPage.canonical_page_id"},
		{ display: "Ranking Page Count", dataPoint: "pageCount"},
	],
	topicExplorerPages: [
		{ display: "Topic", dataPoint: "searchData.topic" },
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Clicks", dataPoint: "searchData.clicks" },
		{ display: "Impressions", dataPoint: "searchData.impressions" },
		// { display: "Keyword Count", dataPoint: "searchData.keywordCount" },
		{ display: "Topic Match %", dataPoint: "matchScore" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	topicReportKeywords: [
		{ display: "Keyword", dataPoint: "keyword"},
		{ display: "Clicks", dataPoint: "clicks"},
		{ display: "Impressions", dataPoint: "impressions"},
		{ display: "Volume", dataPoint: "volume"},
		{ display: "Rank", dataPoint: "position"},
	],
	libraryOverviewKeywords: [
		{ display: "Keyword", dataPoint: "keyword"},
		{ display: "Clicks", dataPoint: "clicks"},
		{ display: "Impressions", dataPoint: "impressions"},
		{ display: "Volume", dataPoint: "volume"},
		{ display: "Rank", dataPoint: "position"},
	],
	actionItems: [
		{ display: "URL", dataPoint: "canonical_page_id" },
		{ display: "Title", dataPoint: "best_title" },
		{ display: "Age (days)", dataPoint: "daysAlive" },
		{ display: "1 Mo. Org. Views", dataPoint: "org1Month.pageviews" },
		{ display: "1 Mo. Org. Bounce Rate", dataPoint: "org1Month.bounceRate" },
		{ display: "1 Mo. Org. % Change", dataPoint: "org1Month.growthPercent" },
		{ display: "Page Score", dataPoint: "calcs.score" },
		{ display: "Opportunity", dataPoint: "calcs.opportunityGrowthMetric" },
		{ display: "Tags", dataPoint: "collections"},
		{ display: "Recommendations", dataPoint: "flags"}
	],
	clientTasks: [
		{ display: "Title", dataPoint: "title" },
		{ display: "Link", dataPoint: "link" },
		{ display: "Status", dataPoint: "status" },
		{ display: "Comments", dataPoint: "comments" },
	],
}