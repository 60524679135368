import { numDisplay } from "@/utils"
import dayjs from "dayjs"

function tableRangeFactory(tableStart, rowIndex, columnIndex, rowSpan, columnSpan) {
	return {
		tableCellLocation: {
			tableStartLocation: {
				index: tableStart,
			},
			rowIndex,
			columnIndex,
		},
		rowSpan,
		columnSpan,
	}
}

function boldTextFactory(startIndex, endIndex) {
	return {
		updateTextStyle: {
			textStyle: { bold: true },
			fields: "bold",
			range: { startIndex, endIndex },
		}
	}
}

function boldUnderlineTextFactory(startIndex, endIndex) {
	return {
		updateTextStyle: {
			textStyle: { underline: true, bold: true},
			fields: "bold,underline",
			range: { startIndex, endIndex },
		}
	}
}

function insertTextFactory(index, text) {
	return {
		insertText: {
			text,
			location: {
				index,
			},
		},
	}
}

const defaultBorderStyle = {
	color: {
		color: {
			rgbColor: { red: 0.85, green: 0.85, blue: 0.85}
		},
	},
	width: { magnitude: 1, unit: "PT" },
	dashStyle: "SOLID",
}

const defaultTableCellStyle = {
	backgroundColor: {
		color: {
			rgbColor: { red: 0.95, green: 0.95, blue: 0.95},
		},
	},
}

export function generateSeoUpdateRequest(page){
	return {
		title: "SEO Update – " + page.meta.title,
		request_body: buildRequests(page)
	}
}

function buildRequests(page){
	let keywordString = ""
	const dates = buildDatesLegacy()

	if (page.keywords && page.keywords.length) {
		page.keywords.forEach(keyword => {
			if (keyword.clicks > 0 || keyword.position >= 50) {
				keywordString += `${numDisplay(keyword.position - 0.5, "s")} – ${keyword.keys[0]} (${numDisplay(keyword.clicks, "m")})\n`
			}
		})
		keywordString = keywordString.slice(0, -1)
	} else {
		keywordString = "NA"
	}

	const requests = [
		{
			createHeader: { type: "DEFAULT" },
		},{
			insertTable: {
				rows: 18,
				columns: 2,
				location: {
					index: 1,
				},
			},
		},{
			updateParagraphStyle: {
				paragraphStyle: {
					lineSpacing: 120,
				},
				fields: "lineSpacing",
				range: {
					startIndex: 2,
					endIndex: 91,
				},
			},
		},{
			updateTableCellStyle: {
				tableCellStyle: {
					borderLeft: defaultBorderStyle,
					borderRight: defaultBorderStyle,
					borderTop: defaultBorderStyle,
					borderBottom: defaultBorderStyle,
				},
				fields: "*",
				tableStartLocation: {
					index: 2,
				},
			},
		},{ 
			mergeTableCells: { tableRange: tableRangeFactory(2, 0, 0, 1, 2)}
		},{ 
			mergeTableCells: { tableRange: tableRangeFactory(2, 3, 0, 1, 2)}
		},{ 
			mergeTableCells: { tableRange: tableRangeFactory(2, 10, 0, 1, 2)}
		},{ 
			mergeTableCells: { tableRange: tableRangeFactory(2, 12, 0, 1, 2)}
		},{
			updateTableCellStyle: {
				tableCellStyle: defaultTableCellStyle,
				fields: "backgroundColor",
				tableRange: tableRangeFactory(2,0,0,1,2),
			},
		},{
			updateTableCellStyle: {
				tableCellStyle: defaultTableCellStyle,
				fields: "backgroundColor",
				tableRange: tableRangeFactory(2,3,0,1,2),
			},
		},{
			updateTableCellStyle: {
				tableCellStyle: defaultTableCellStyle,
				fields: "backgroundColor",
				tableRange: tableRangeFactory(2,10,0,1,2),
			},
		},{
			updateTableCellStyle: {
				tableCellStyle: defaultTableCellStyle,
				fields: "backgroundColor",
				tableRange: tableRangeFactory(2,12,0,1,2),
			},
		},{
			updateTextStyle: {
				textStyle: {
					fontSize: { magnitude: 9, unit: "PT"},
					weightedFontFamily: { fontFamily: "Proxima Nova", weight: 400},
				},
				fields: "*",
				range: { startIndex: 5, endIndex: 92 },
			},
		},{
			updateTextStyle: {
				textStyle: {
					bold: true,
					fontSize: { magnitude: 11, unit: "PT" },
				},
				fields: "bold,fontSize",
				range: { startIndex: 5, endIndex: 6 },
			},
		},
		boldTextFactory(10,11),
		boldTextFactory(15,16),
		boldUnderlineTextFactory(20,21),
		boldTextFactory(25,26),
		boldTextFactory(30,31),
		boldTextFactory(35,36),
		boldTextFactory(40,41),
		boldTextFactory(45,46),
		boldTextFactory(50,51),
		boldUnderlineTextFactory(55,56),
		boldTextFactory(60,61),
		boldUnderlineTextFactory(65,66),
		boldTextFactory(70,71),
		boldTextFactory(75,76),
		boldTextFactory(80,81),
		boldTextFactory(85,86),
		boldTextFactory(90,91),
		insertTextFactory(92, page.metaScrape?.description ? page.metaScrape?.description + " " : " "),
		insertTextFactory(90, "Meta Desc. (160)"),
		insertTextFactory(87, page.meta.title),
		insertTextFactory(85, "Page Title (60)"),
		insertTextFactory(82, page.metaScrape?.h1 ? page.metaScrape?.h1 + " " : " "),
		insertTextFactory(80, "H1 (90)"),
		insertTextFactory(77, page.meta.cleanUrl),
		insertTextFactory(75, "URL (90)"),
		insertTextFactory(70, "Focus Keyword"),
		insertTextFactory(65, "Page Info"),
		insertTextFactory(62, keywordString),
		insertTextFactory(60, "Current Rankings"),
		insertTextFactory(55, "Keyword Audit"),
		insertTextFactory(50, "Snapshot"),
		insertTextFactory(47, page.metaScrape?.wordCount ? numDisplay(page.metaScrape?.wordCount) : " "),
		insertTextFactory(45, "Word Count"),
		insertTextFactory(42, page?.org1Month.pageviews > 5 ? numDisplay(page.org1Month.bounceRate, "%s") : "NA"),
		insertTextFactory(40, "Bounce Rate"),
		insertTextFactory(37, page?.org1Month.pageviews ? numDisplay(page?.org1Month.pageviews) + " " : " "),
		insertTextFactory(35, "Views (Organic)"),
		insertTextFactory(32, page?.timeSeries?.all?.getData(1, "month").pageviews ? numDisplay(page?.timeSeries?.all?.getData(1, "month").pageviews) + " " : " "),
		insertTextFactory(30, "Views (All)"),
		insertTextFactory(27, page.desktopSpeed ? page.desktopSpeed.toString() + " " : " "),
		insertTextFactory(27, "/ "),
		insertTextFactory(27, page.mobileSpeed ? page.mobileSpeed.toString() + " " : " "),
		insertTextFactory(25, "PageSpeed"),
		insertTextFactory(20, "Page Audit – " + dates.longDisplay[11]),
		insertTextFactory(17, dates.today),
		insertTextFactory(15, "Date"),
		insertTextFactory(12, page.meta.title),
		{
			updateTextStyle: {
				textStyle: {
					link: {
						url: page.meta.cleanUrl,
					},
				},
				fields: "link",
				range: {
					startIndex: 12,
					endIndex: 12 + page.meta.title.length,
				},
			},
		},
		insertTextFactory(10, "Page"),
		insertTextFactory(5, "⚒️ SEO Update"),
		{
			updateTableColumnProperties: {
				tableStartLocation: {
					index: 2,
				},
				columnIndices: [0],
				tableColumnProperties: {
					widthType: "FIXED_WIDTH",
					width: {
						magnitude: 110,
						unit: "PT",
					},
				},
				fields: "*",
			},
		},
	]
	return JSON.stringify(requests)
}

function buildDatesLegacy() {
	let today = new Date()
	let firstDayOfYear = new Date(today.getFullYear(), today.getMonth() - 12, 1)
	let lastDayOfyear = new Date(today.getFullYear(), today.getMonth(), 1)
	lastDayOfyear.setDate(0)

	let dates = {
		today: dayjs(today).format("MMM D, YYYY"),
		allYear: {
			startDate: firstDayOfYear.toISOString().split("T")[0],
			endDate: lastDayOfyear.toISOString().split("T")[0],
		},
		raw: [],
		longDisplay: [],
		shortDisplay: [],
		allMonths: [],
		days: [],
	}

	for (var i = 0; i < 12; i++) {
		let s = 12 - i
		let first = new Date(today.getFullYear(), today.getMonth() - s, 1)
		let last = new Date(today.getFullYear(), today.getMonth() - s + 1, 1)
		last.setDate(0)
		let mo = {
			startDate: first.toISOString().split("T")[0],
			endDate: last.toISOString().split("T")[0],
		}
		dates.allMonths.push(mo)
		dates.raw.push(first)
		let displayMonth = dayjs(first)
		dates.shortDisplay.push(displayMonth.format("MMM YY"))
		dates.longDisplay.push(displayMonth.format("MMMM YYYY"))
	}

	return dates
}