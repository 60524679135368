import { useModel as _useModel } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "flex gap-x-2"
};
import { useRouteQuery } from "@vueuse/router";

import { useLibraryStore, useLocalStore } from '@/stores';
import { ref, onMounted } from 'vue';
export default {
  __name: 'LibraryAnalysisTable',
  props: {
    "conf": {
      type: Object
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const libraryStore = useLibraryStore();
    const {
      pageTableSettings: settings,
      tableSettings
    } = useLocalStore();
    const conf = _useModel(__props, "conf");
    const monthRange = useRouteQuery("month_range", 3, {
      transform: Number
    });
    const monthRangeOptions = ref([{
      title: "3 Month",
      value: 3
    }, {
      title: "6 Month",
      value: 6
    }, {
      title: "12 Month",
      value: 12
    }]);
    onMounted(init);
    async function init() {
      await libraryStore.getCollectionsPages();
      conf.value.localData = libraryStore.pages;
      conf.value.get();
    }
    return (_ctx, _cache) => {
      const _component_TableSearch = _resolveComponent("TableSearch");
      const _component_PageTableNavResetFilters = _resolveComponent("PageTableNavResetFilters");
      const _component_BaseTabs = _resolveComponent("BaseTabs");
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_PageTableMenu = _resolveComponent("PageTableMenu");
      const _component_PageColumnTitleOrUrl = _resolveComponent("PageColumnTitleOrUrl");
      const _component_TableColumn = _resolveComponent("TableColumn");
      const _component_PageColumnCollections = _resolveComponent("PageColumnCollections");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: conf.value,
        "onUpdate:conf": _cache[2] || (_cache[2] = $event => conf.value = $event),
        "link-to": "PageSingle",
        class: _normalizeClass(["min-w-[950px]", {
          'slim': _unref(tableSettings).compact
        }])
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_TableSearch, {
          class: "max-w-sm xl:max-w-md"
        }), _createVNode(_component_PageTableNavResetFilters), _createVNode(_component_BaseTabs, {
          tabs: _unref(monthRangeOptions),
          "onUpdate:tabs": _cache[0] || (_cache[0] = $event => _isRef(monthRangeOptions) ? monthRangeOptions.value = $event : null),
          selected: _unref(monthRange),
          "onUpdate:selected": _cache[1] || (_cache[1] = $event => _isRef(monthRange) ? monthRange.value = $event : null),
          class: "ml-auto mr-8"
        }, null, 8, ["tabs", "selected"]), _createVNode(_component_TableNavCopy, {
          items: conf.value.getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_PageTableMenu)])]),
        columns: _withCtx(({
          item
        }) => [_createVNode(_component_PageColumnTitleOrUrl, {
          "title-url-toggle": _unref(settings).titleUrlToggle,
          class: "w-full"
        }, null, 8, ["title-url-toggle"]), _createVNode(_component_TableColumn, {
          class: "w-24",
          "sort-by": "calcs.score"
        }, {
          title: _withCtx(() => [_createTextVNode(" Score ")]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(item.calcs?.score), 1)]),
          _: 2
        }, 1024), _createVNode(_component_TableColumn, {
          class: "w-24",
          "sort-by": "calcs.scale"
        }, {
          title: _withCtx(() => [_createTextVNode(" Scale ")]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(item.calcs?.scale), 1)]),
          _: 2
        }, 1024), _createVNode(_component_TableColumn, {
          class: "w-24",
          "sort-by": "calcs.engagement"
        }, {
          title: _withCtx(() => [_createTextVNode(" Engagement ")]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(item.calcs?.engagement), 1)]),
          _: 2
        }, 1024), _createVNode(_component_TableColumn, {
          class: "w-24",
          "sort-by": "calcs.consistency"
        }, {
          title: _withCtx(() => [_createTextVNode(" Consistency ")]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(item.calcs?.consistency), 1)]),
          _: 2
        }, 1024), _createVNode(_component_TableColumn, {
          class: "w-24",
          "sort-by": "calcs.positivity"
        }, {
          title: _withCtx(() => [_createTextVNode(" Positivity ")]),
          default: _withCtx(() => [_createTextVNode(" " + _toDisplayString(item.calcs?.positivity), 1)]),
          _: 2
        }, 1024), _unref(settings).tags ? (_openBlock(), _createBlock(_component_PageColumnCollections, {
          key: 0,
          class: "w-[190px] 2xl:w-[250px]"
        })) : _createCommentVNode("", true)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 1
      }, 8, ["conf", "class"]);
    };
  }
};