import { useModel as _useModel } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-sm flex items-center pr-2"
};
const _hoisted_2 = {
  class: "flex items-center justify-center space-x-1 rounded-md border border-gray-200 bg-gray-100"
};
export default {
  __name: 'TableMenuToggleTabs',
  props: {
    "toggle": {
      type: Boolean,
      required: true
    },
    "toggleModifiers": {}
  },
  emits: ["update:toggle"],
  setup(__props) {
    const toggle = _useModel(__props, "toggle");
    return (_ctx, _cache) => {
      const _component_TableMenuItem = _resolveComponent("TableMenuItem");
      return _openBlock(), _createBlock(_component_TableMenuItem, {
        class: "flex justify-between items-center",
        onClick: _cache[0] || (_cache[0] = $event => toggle.value = !toggle.value)
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]), _createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
          class: _normalizeClass(["border rounded-md w-[50%] px-3 py-0.5 text-sm font-medium", {
            'border-white bg-white shadow text-gray-700': !toggle.value,
            'text-gray-400 hover:text-gray-700 border-gray-100': toggle.value
          }])
        }, [_renderSlot(_ctx.$slots, "tabOne")], 2), _createElementVNode("button", {
          class: _normalizeClass(["border rounded-md w-[50%] px-3 py-0.5 text-sm font-medium", {
            'border-white bg-white shadow text-gray-700': toggle.value,
            'text-gray-400 hover:text-gray-700 border-gray-100': !toggle.value
          }])
        }, [_renderSlot(_ctx.$slots, "tabTwo")], 2)])]),
        _: 3
      });
    };
  }
};