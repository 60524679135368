import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";

import { inject, computed } from 'vue';
export default {
  __name: 'KeywordsColumnVolume',
  props: {
    viewLength: {
      type: String,
      default: "30 days"
    }
  },
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const data = computed(() => item ? item.volume : undefined);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCellNumber = _resolveComponent("TableCellNumber");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "volume"
      }, {
        title: _withCtx(() => [_createTextVNode(" Volume ")]),
        tooltip: _withCtx(() => [_createTextVNode(" Our estimated monthly search volume for each term given enough impressions. ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCellNumber, {
        key: 1,
        data: _unref(data),
        class: _normalizeClass({
          'text-slate-300': !_unref(data)
        })
      }, null, 8, ["data", "class"]));
    };
  }
};