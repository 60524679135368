import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = ["title"];
export default {
  __name: 'KeywordsColumnKeyword',
  props: {
    searchTerm: {
      type: [String, null],
      default: null
    }
  },
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const keyword = computed(() => item ? item.keyword : "");
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "keyword"
      }, {
        title: _withCtx(() => [_createTextVNode(" Keyword ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1,
        class: "font-medium"
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          class: _normalizeClass(["truncate", {
            'text-blue-600 font-bold': _unref(keyword) === __props.searchTerm
          }]),
          title: _unref(keyword)
        }, _toDisplayString(_unref(keyword)), 11, _hoisted_1)]),
        _: 1
      }));
    };
  }
};