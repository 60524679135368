import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, isRef as _isRef, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "flex mb-3 flex-wrap overflow-hidden"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "flex gap-x-2"
};
import { useRouteQuery } from "@vueuse/router";
import { getLibraryPages } from "@/api/library";
import { postTag, listTags, deleteTag } from "@/api/tags";

import { useLocalStore } from '@/stores';
import { useRoute } from 'vue-router';
import { ref, reactive, onMounted } from 'vue';
import { PageTableConfig } from '@/utils';
export default {
  __name: 'NewCollectionsTable',
  setup(__props) {
    const {
      pageTableSettings: settings,
      tableSettings
    } = useLocalStore();
    const route = useRoute();
    const tagList = ref([]);
    const name = ref("");
    const monthRange = useRouteQuery("month_range", 3, {
      transform: Number
    });
    const monthRangeOptions = ref([{
      title: "3 Month",
      value: 3
    }, {
      title: "6 Month",
      value: 6
    }, {
      title: "12 Month",
      value: 12
    }]);
    const conf = reactive(new PageTableConfig({
      sortField: "page_views",
      sortGroup: "org_last_3_months_data",
      copyFormat: "pages",
      pageSize: 100,
      checkboxes: true,
      searchText: useRouteQuery("search", ""),
      getAsync: async config => {
        return await getLibraryPages(route.params.libraryId, config);
      }
    }));
    onMounted(() => {
      conf.get();
      getTags();
    });
    async function makeTag() {
      const pageList = conf.getCheckedItems() || [];
      await postTag(route.params.libraryId, {
        name: name.value,
        page_ids: pageList.map(p => p.id),
        page_query: conf.filterConf.getFormatted()
      });
      getTags();
    }
    async function removeTag(id) {
      await deleteTag(route.params.libraryId, id);
      getTags();
    }
    async function getTags() {
      listTags(route.params.libraryId).then(list => {
        tagList.value = list.map(tag => {
          tag.size = tag["pages"].length || 0;
          delete tag["pages"];
          delete tag["description"];
          delete tag["icon"];
          delete tag["created"];
          return tag;
        });
      });
    }
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      const _component_BaseBubble = _resolveComponent("BaseBubble");
      const _component_BaseButton = _resolveComponent("BaseButton");
      const _component_BaseInputText = _resolveComponent("BaseInputText");
      const _component_TableSearch = _resolveComponent("TableSearch");
      const _component_PageTableNavFilters = _resolveComponent("PageTableNavFilters");
      const _component_PageTableNavResetFilters = _resolveComponent("PageTableNavResetFilters");
      const _component_BaseTabs = _resolveComponent("BaseTabs");
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_PageTableMenu = _resolveComponent("PageTableMenu");
      const _component_PageColumnTitleOrUrl = _resolveComponent("PageColumnTitleOrUrl");
      const _component_PageColumnViews = _resolveComponent("PageColumnViews");
      const _component_PageColumnSessions = _resolveComponent("PageColumnSessions");
      const _component_PageColumnBounceRate = _resolveComponent("PageColumnBounceRate");
      const _component_PageColumnCollections = _resolveComponent("PageColumnCollections");
      const _component_PageColumnAge = _resolveComponent("PageColumnAge");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: _unref(conf),
        "onUpdate:conf": _cache[3] || (_cache[3] = $event => _isRef(conf) ? conf.value = $event : null),
        "link-to": "PageSingle",
        class: _normalizeClass(["min-w-[950px]", {
          'slim': _unref(tableSettings).compact
        }])
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tagList), tag => {
          return _openBlock(), _createBlock(_component_BaseBubble, {
            key: tag,
            class: "mr-2 flex-none"
          }, {
            default: _withCtx(() => [_createVNode(_component_router_link, {
              to: {
                name: 'TagSingle',
                params: {
                  tagId: tag.id
                }
              },
              class: "pr-2 hover:cursor-pointer hover:text-blue-600"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.name), 1)]),
              _: 2
            }, 1032, ["to"]), _createElementVNode("span", {
              class: "pl-2 border-l hover:cursor-pointer hover:text-blue-600",
              onClick: $event => removeTag(tag.id)
            }, "x", 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128)), _createVNode(_component_BaseButton, {
          class: "ml-auto mr-2",
          onClick: makeTag
        }, {
          default: _withCtx(() => [_createTextVNode(" Make Collection ")]),
          _: 1
        }), _createVNode(_component_BaseInputText, {
          state: _unref(name),
          "onUpdate:state": _cache[0] || (_cache[0] = $event => _isRef(name) ? name.value = $event : null),
          name: "collectionName",
          class: "mr-2"
        }, null, 8, ["state"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_TableSearch, {
          class: "max-w-sm xl:max-w-md"
        }), _createVNode(_component_PageTableNavFilters), _createVNode(_component_PageTableNavResetFilters), _createVNode(_component_BaseTabs, {
          tabs: _unref(monthRangeOptions),
          "onUpdate:tabs": _cache[1] || (_cache[1] = $event => _isRef(monthRangeOptions) ? monthRangeOptions.value = $event : null),
          selected: _unref(monthRange),
          "onUpdate:selected": _cache[2] || (_cache[2] = $event => _isRef(monthRange) ? monthRange.value = $event : null),
          class: "ml-auto mr-8"
        }, null, 8, ["tabs", "selected"]), _createVNode(_component_TableNavCopy, {
          items: _unref(conf).getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_PageTableMenu)])]),
        columns: _withCtx(() => [_createVNode(_component_PageColumnTitleOrUrl, {
          "title-url-toggle": _unref(settings).titleUrlToggle,
          class: "w-full"
        }, null, 8, ["title-url-toggle"]), _unref(settings).views ? (_openBlock(), _createBlock(_component_PageColumnViews, {
          key: 0,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).sessions ? (_openBlock(), _createBlock(_component_PageColumnSessions, {
          key: 1,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).bounceRate ? (_openBlock(), _createBlock(_component_PageColumnBounceRate, {
          key: 2,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).tags ? (_openBlock(), _createBlock(_component_PageColumnCollections, {
          key: 3,
          class: "w-[190px] 2xl:w-[250px]"
        })) : _createCommentVNode("", true), _unref(settings).age ? (_openBlock(), _createBlock(_component_PageColumnAge, {
          key: 4,
          class: "w-20 xl:w-[100px]"
        })) : _createCommentVNode("", true)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 1
      }, 8, ["conf", "class"]);
    };
  }
};