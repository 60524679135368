import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { useNotificationStore, useLibraryStore } from '@/stores';
import { inject } from 'vue';
import { exportItemsToCsv } from '@/utils';
export default {
  __name: 'TableMenuGroupCopy',
  setup(__props) {
    const notificationStore = useNotificationStore();
    const libraryStore = useLibraryStore();
    const conf = inject("conf");
    async function exportAll() {
      notificationStore.setNotification("Preparing export", "Your download will start soon.");
      const data = await conf.value.get({
        toExport: true
      });
      if (data) {
        exportItemsToCsv(data, conf.value?.copyFormat, libraryStore.name + " Library");
      }
    }
    return (_ctx, _cache) => {
      const _component_TableMenuItemCopy = _resolveComponent("TableMenuItemCopy");
      const _component_hero_arrow_down_16_solid = _resolveComponent("hero-arrow-down-16-solid");
      const _component_PopoverButton = _resolveComponent("PopoverButton");
      const _component_TableMenuItem = _resolveComponent("TableMenuItem");
      const _component_TableMenuGroup = _resolveComponent("TableMenuGroup");
      return _openBlock(), _createBlock(_component_TableMenuGroup, null, {
        default: _withCtx(() => [_createVNode(_component_TableMenuItemCopy, {
          items: _unref(conf).getCheckedItems(),
          format: _unref(conf).copyFormat
        }, {
          default: _withCtx(() => [_createTextVNode(" Copy selected ")]),
          _: 1
        }, 8, ["items", "format"]), _createVNode(_component_TableMenuItemCopy, {
          items: _unref(conf).rows,
          format: _unref(conf).copyFormat
        }, {
          default: _withCtx(() => [_createTextVNode(" Copy visible ")]),
          _: 1
        }, 8, ["items", "format"]), _createVNode(_component_TableMenuItem, {
          onClick: exportAll
        }, {
          default: _withCtx(() => [_createVNode(_component_PopoverButton, {
            as: "div",
            class: "flex items-center"
          }, {
            default: _withCtx(() => [_createVNode(_component_hero_arrow_down_16_solid, {
              class: "w-4 h-4 mr-2"
            }), _createTextVNode("Export all to CSV ")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};