import { useModel as _useModel } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "text-sm flex items-center"
};
export default {
  __name: 'TableMenuToggle',
  props: {
    "toggle": {
      type: Boolean,
      required: true
    },
    "toggleModifiers": {}
  },
  emits: ["update:toggle"],
  setup(__props) {
    const toggle = _useModel(__props, "toggle");
    return (_ctx, _cache) => {
      const _component_Switch = _resolveComponent("Switch");
      const _component_SwitchGroup = _resolveComponent("SwitchGroup");
      const _component_TableMenuItem = _resolveComponent("TableMenuItem");
      return _openBlock(), _createBlock(_component_TableMenuItem, {
        onClick: _cache[2] || (_cache[2] = $event => toggle.value = !toggle.value)
      }, {
        default: _withCtx(() => [_createVNode(_component_SwitchGroup, {
          as: "div",
          class: "flex items-center w-full justify-between"
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]), _createVNode(_component_Switch, {
            modelValue: toggle.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => toggle.value = $event),
            class: _normalizeClass(["relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none", [toggle.value ? 'bg-blue-600' : 'bg-gray-200']]),
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, {
            default: _withCtx(() => [_createElementVNode("span", {
              "aria-hidden": "true",
              class: _normalizeClass(["pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out flex-none", [toggle.value ? 'translate-x-5' : 'translate-x-0']])
            }, null, 2)]),
            _: 1
          }, 8, ["modelValue", "class"])]),
          _: 3
        })]),
        _: 3
      });
    };
  }
};