import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = {
  scope: "col",
  class: "select-none text-left"
};
const _hoisted_2 = {
  class: "px-3 h-full flex items-center"
};
export default {
  __name: 'TableCellHeader',
  props: {
    sortBy: String,
    sortGroup: String,
    reverse: Boolean
  },
  setup(__props) {
    const conf = inject("conf");
    const props = __props;
    const sortString = computed(() => {
      if (!props.sortBy) {
        return;
      } else {
        return props.sortGroup ? `${props.sortBy}_${props.sortGroup}` : props.sortBy;
      }
    });
    const classString = computed(() => {
      if (conf.value && conf.value.dir === "DESC") {
        return props.reverse ? "asc" : "desc";
      } else if (conf.value && conf.value.dir === "ASC") {
        return props.reverse ? "desc" : "asc";
      } else {
        return "";
      }
    });
    function sort() {
      if (props.sortBy) {
        conf.value.setSort(props.sortBy, props.sortGroup, props.reverse);
        conf.value.get();
      }
    }
    return (_ctx, _cache) => {
      const _component_BaseTooltip = _resolveComponent("BaseTooltip");
      return _openBlock(), _createElementBlock("th", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createElementVNode("span", {
        class: _normalizeClass(["inline-block tooltip-wrapper group", [_unref(classString), {
          'hover:cursor-pointer': __props.sortBy,
          'active': _unref(conf) && _unref(conf).by === _unref(sortString)
        }]]),
        onClick: sort
      }, [_ctx.$slots.tooltip ? (_openBlock(), _createBlock(_component_BaseTooltip, {
        key: 0,
        class: "top-0"
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "tooltip")]),
        _: 3
      })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "title")], 2)])]);
    };
  }
};