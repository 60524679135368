import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { inject } from 'vue';
export default {
  __name: 'TableSearch',
  setup(__props) {
    const conf = inject("conf");
    async function doSearch() {
      conf.value.get();
    }
    return (_ctx, _cache) => {
      const _component_BaseSearch = _resolveComponent("BaseSearch");
      return _openBlock(), _createBlock(_component_BaseSearch, {
        button: false,
        class: "w-[50%] flex-none",
        onSearch: doSearch
      });
    };
  }
};