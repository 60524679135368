import dayjs from "dayjs"

export function mapLibraryStatus(data) {
	console.log(data)
	let status = {}

	const onboardingStage = data.onboarding_status.stage
	const isOnboardingComplete = data.is_onboarding_complete || false
	const syncDate = data.most_recent_request_end_date ? dayjs(data.most_recent_request_end_date) : null
	const isSynced = syncDate?.valueOf() >= dayjs().subtract(4, "day")

	if (syncDate && !isSynced) {
		status = statusMap["OUT_OF_SYNC"]
		status.title = "Library not synced 🚫"
		status.tooltip = "When possible, we keep library data up to date as of 72 hours ago. If your library is not staying synced, contact us at team@ercule.co"
	} else {
		status = statusMap[onboardingStage] ? statusMap[onboardingStage] : statusMap["UNKNOWN"]

		if (status.tier == 0) {
			status.title = "Library Synced ✓"
			status.tooltip = "Your library is up to date with the most recent complete data – usually 72 hours old."
		} else {
			status.title = "Library syncing..."
			status.tooltip = "Your library is currently syncing. We intelligently clean, analyze and roll up all your data, so this may take a few minutes."
		}
	}

	return { ...status, syncDate, isSynced, isOnboardingComplete}
}

const statusMap = {
	"UNKNOWN": {
		description: "Getting library data.",
		percent: 0,
		tier: -1
	},
	"TO_DO": {
		description: "About to start syncing your library.",
		percent: 5,
		tier: 10
	},
	"METADATA_FETCH": {
		description: "Getting library metadata.",
		percent: 5,
		tier: 10
	},
	"METADATA_PROCESS": {
		description: "Getting library metadata.",
		percent: 5,
		tier: 9
	},
	"INITIAL_PROCESS": {
		description: "Getting recent history.",
		percent: 10,
		tier: 8
	},
	"MINIMUM_USEFUL_FETCH": {
		description: "Getting recent history.",
		percent: 15,
		tier: 7
	},
	"MINIMUM_USEFUL_PROCESS": {
		description: "Getting recent history.",
		percent: 20,
		tier: 6
	},
	"MINIMUM_USEFUL_POPULATE": {
		description: "Getting historical data.",
		percent: 35,
		tier: 5
	},
	"HISTORICAL_METADATA_FETCH": {
		description: "Getting historical data.",
		percent: 40,
		tier: 4
	},
	"HISTORICAL_METADATA_PROCESS": {
		description: "Getting all pages.",
		percent: 45,
		tier: 3
	},
	"FULL_FETCH": {
		description: "Analyzing library pages.",
		percent: 60,
		tier: 2
	},
	"FULL_PROCESS": {
		description: "Analyzing library pages.",
		percent: 80,
		tier: 1
	},
	"FULL_POPULATE": {
		description: "Analyzing library pages.",
		percent: 100,
		tier: 0
	},
	"FULL_CALCULATE": {
		description: "Analyzing library pages.",
		percent: 100,
		tier: 0
	},
	"REGENERATE_DYNAMIC_TAGS": {
		description: "Your library is fully synced.",
		percent: 100,
		tier: 0
	},
	"FULL_TAG_ROLLUP": {
		description: "Your library is fully synced.",
		percent: 100,
		tier: 0
	},
	"OUT_OF_SYNC": {
		description: "Your library is out of date.",
		percent: 0,
		tier: 0
	},
}