import { api } from "@/api"
import { processPages, scorePages } from "@/utils"

export async function getLibraryPages(libraryId, options) {
	const params = { 
		pn: options.pn || 1,
		ps: options.ps || 10,
		so_group: options.so_group || undefined,
		so_field: options.so_field || "page_views",
		so_direction: options.so_direction || "DESC",
		filter_expression: options.filter_expression || undefined,
		tags: options.tags || undefined
	}

	const res = await api.get(`/api/libraries/${libraryId}/pages`, { 
		params: {
			json: JSON.stringify(params)
		} 
	})

	if (res?.data?.results) {
		const count = res.data?.count || 0
		const processedPages = processPages(res.data.results)
		const pages = scorePages(processedPages)
		console.log(pages)
		return { count, pages }
	} else {
		return
	}
}

