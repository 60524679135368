export class PageFilterConfig {
	constructor() {
		this.id = Date.now()
		this.filterGroups = []
		this.expression = "AND_GROUP"
		this.addFilter()
	}

	addFilter(options) {
		this.filterGroups.push(new FilterSingleConfig(options))
	}

	addGroup(options) {
		this.filterGroups.push(new FilterGroupConfig(options))
	}

	removeGroup(index) {
		this.filterGroups.splice(index, 1)
	}

	clearFilters() {
		this.filterGroups = []
		this.expression = "AND_GROUP"
		this.addFilter()
	}

	getFormatted() {
		return formatFilter(this.expression, this.filterGroups)
	}

	getNonEmptyGroupCount() {
		return this.filterGroups.map(f => f.getFormatted()).filter(f => f !== undefined).length || 0
	}
}


export class FilterGroupConfig {
	constructor() {
		this.id = Date.now()
		this.filters = []
		this.expression = "AND_GROUP"
	}

	getFormatted() {
		return formatFilter(this.expression, this.filters)
	}

	addFilter(options) {
		this.filters.push(new FilterSingleConfig(options))
	}

	removeFilter(index) {
		this.filters.splice(index, 1)
	}
}


export class FilterSingleConfig {
	constructor(options = {}) {
		this.id = Date.now()
		this.field = options.field || null
		this.metricFilter = options.metricFilter || { inputNumber: 0, matchType: "gt" }
		this.textFilter = options.textFilter || { inputText: "", matchType: "contains" }
		this.pageDataFilter = options.pageDataFilter || { inputNumber: 0, matchType: "gt", segment: "ORG", months: 3 }
		this.fieldOptions = [
			{value: null, type: "SPACER", display: "–– Meta ––"},
			{value: "CANONICAL_PAGE_ID", type: "TEXT", display: "URL"},
			{value: "BEST_TITLE", type: "TEXT", display: "Title"},
			{value: "TITLE_OR_URL", type: "TEXT", display: "URL or Title"},
			{value: null, type: "SPACER", display: "–– Metrics ––"},
			{value: "PAGE_VIEWS", type: "PAGE_DATA", display: "Views"},
			{value: "SESSIONS", type: "PAGE_DATA", display: "Sessions"},
			{value: null, type: "SPACER", display: "–– Scores ––"},
			{value: "SCALE", type: "METRIC", display: "Scale"},
			{value: "POSITIVITY", type: "METRIC", display: "Positivity"},
			{value: "ENGAGEMENT", type: "METRIC", display: "Engagement"},
			{value: "CONSISTENCY", type: "METRIC", display: "Consistency"},
		]
	}

	getFormatted() {
		if (!this.field) return undefined

		if (this.field.type === "METRIC") {
			const f = {
				filter_expression_type: "METRIC",
				filter: { metric: this.field.value }
			}
			f.filter[this.metricFilter.matchType] = this.metricFilter.inputNumber
			return f
		} else if (this.field.type === "TEXT") {
			const f = {
				filter_expression_type: "TEXT_CONTAINS",
				filter: {
					search_text: this.textFilter.inputText,
					search_fields: this.field.value === "TITLE_OR_URL" ? ["CANONICAL_PAGE_ID", "BEST_TITLE"] : [this.field.value]
				}
			}

			if (this.textFilter.matchType === "does_not_contain") {
				return { filter_expression_type: "NOT_EXPRESSION", not_expression: f }
			} else {
				return f
			}
		} else if (this.field.type === "PAGE_DATA") {
			const f = {
				filter_expression_type: "PAGE_DATA",
				filter: {
					"data_field": this.field.value,
					"request_segment": this.pageDataFilter.segment,
					"num_months": this.pageDataFilter.months
				}
			}
			f.filter[this.pageDataFilter.matchType] = this.pageDataFilter.inputNumber
			return f
		}
	}
}


function formatFilter(expression, items) {
	if (items.length <= 0) return undefined
	const f = { filter_expression_type: expression }
	const allItems = items.map(f => f.getFormatted()).filter(f => f !== undefined)
	f[expression.toLowerCase()] = allItems

	if (allItems.length === 1) {
		return allItems[0]
	} else if (allItems.length > 1) {
		return f
	} else {
		return undefined
	}
}
