import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";

import { computed } from 'vue';
const _hoisted_1 = {
  class: "space-y-5 bg-gray-100 p-10 rounded-lg"
};
const _hoisted_2 = {
  class: "ml-4"
};
export default {
  __name: 'CreateLibraryLoadingList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentItem: {
      type: Number,
      required: true
    },
    complete: Boolean
  },
  setup(__props) {
    const props = __props;
    const loadingItems = computed(() => {
      return props.items.map((item, index) => {
        if (props.complete || index < props.currentItem) {
          item.status = item.error ? item.error : "SUCCESS";
        } else if (index === props.currentItem) {
          item.status = "LOADING";
        } else if (index > props.currentItem) {
          item.status = "WAITING";
        }
        return item;
      });
    });
    return (_ctx, _cache) => {
      const _component_hero_arrow_path_16_solid = _resolveComponent("hero-arrow-path-16-solid");
      const _component_hero_exclamation_circle = _resolveComponent("hero-exclamation-circle");
      const _component_hero_check_16_solid = _resolveComponent("hero-check-16-solid");
      const _component_hero_ellipsis_horizontal = _resolveComponent("hero-ellipsis-horizontal");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(loadingItems), item => {
        return _openBlock(), _createElementBlock("p", {
          key: item,
          class: _normalizeClass(["text-stone-800", {
            'opacity-60': item.status === 'WAITING'
          }])
        }, [item.status === 'LOADING' ? (_openBlock(), _createBlock(_component_hero_arrow_path_16_solid, {
          key: 0,
          class: "animate-spin h-5 w-5 mr-1 inline"
        })) : item.status === 'WARN' ? (_openBlock(), _createBlock(_component_hero_exclamation_circle, {
          key: 1,
          class: "h-6 w-6 text-yellow-500 inline"
        })) : item.status === 'ERROR' ? (_openBlock(), _createBlock(_component_hero_exclamation_circle, {
          key: 2,
          class: "h-6 w-6 text-red-500 inline"
        })) : item.status === 'SUCCESS' ? (_openBlock(), _createBlock(_component_hero_check_16_solid, {
          key: 3,
          class: "h-6 w-6 text-green-500 inline"
        })) : item.status === 'WAITING' ? (_openBlock(), _createBlock(_component_hero_ellipsis_horizontal, {
          key: 4,
          class: "h-6 w-6 inline"
        })) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_2, _toDisplayString(item.text), 1)], 2);
      }), 128))]);
    };
  }
};