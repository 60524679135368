import { defineStore } from "pinia"
import { useStorage } from "@vueuse/core"

export const useLocalStore = defineStore("localStore", ()=>{

	const tableSettings = useStorage("ercule-shared-table-settings", { 
		compact: false, 
	})

	const pageTableSettings = useStorage("ercule-page-table-settings", { 
		titleUrlToggle: false, 
		views: true, 
		sessions: false, 
		bounceRate: false, 
		tags: true,
		age: false
	})

	return { tableSettings, pageTableSettings }
})
