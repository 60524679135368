import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { computed } from 'vue';
const _hoisted_1 = {
  class: "m-0 text-xs font-normal normal-case tracking-normal"
};
export default {
  __name: 'BaseTooltip',
  props: {
    size: {
      type: String,
      default: "default"
    },
    color: {
      type: String,
      default: "light"
    },
    visibility: {
      type: [Boolean, null],
      default: null
    },
    delay: {
      type: String,
      default: "md"
    }
  },
  setup(__props) {
    const props = __props;
    const delayClasses = computed(() => ({
      "group-hover:delay-700 delay-75": props.delay === "xl",
      "group-hover:delay-500 delay-75": props.delay === "lg",
      "group-hover:delay-300 delay-75": props.delay === "md",
      "group-hover:delay-150 delay-75": props.delay === "sm",
      "delay-0": props.delay === "xs"
    }));
    const visibilityClasses = computed(() => ({
      "invisible opacity-0 group-hover:visible group-hover:opacity-100": props.visibility === null,
      "invisible opacity-0": props.visibility === false,
      "visible opacity-100": props.visibility === true
    }));
    const colorClasses = computed(() => ({
      "bg-zinc-800 text-zinc-50 border-zinc-700": props.color === "dark",
      "bg-white text-zinc-900 border-zinc-200": props.color === "light"
    }));
    const sizeClasses = computed(() => ({
      "whitespace-nowrap": props.size === "auto",
      "w-64": props.size === "default",
      "w-[300px]": props.size === "lg"
    }));
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tooltip-content absolute p-3 transition-all duration-100 z-[999999]", [_unref(visibilityClasses), _unref(sizeClasses), _unref(delayClasses)]])
      }, [_createElementVNode("div", {
        class: _normalizeClass(["px-3 py-2 shadow rounded-lg pointer-events-auto border overflow-hidden", [_unref(colorClasses)]])
      }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")])], 2)], 2);
    };
  }
};