import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = ["href", "title"];
const _hoisted_2 = ["title"];
export default {
  __name: 'ClientDashboardColumnTitle',
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const title = computed(() => item ? item.title : undefined);
    const link = computed(() => item ? item.link : undefined);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0
      }, {
        title: _withCtx(() => [_createTextVNode(" Title ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1,
        class: "font-medium"
      }, {
        default: _withCtx(() => [_unref(link) ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _unref(link),
          target: "_blank",
          class: "truncate hover:text-blue-600 hover:cursor-pointer w-full",
          title: _unref(title)
        }, _toDisplayString(_unref(title)), 9, _hoisted_1)) : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "truncate",
          title: _unref(title)
        }, _toDisplayString(_unref(title)), 9, _hoisted_2))]),
        _: 1
      }));
    };
  }
};