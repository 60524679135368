import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { useLocalStore } from '@/stores';
export default {
  __name: 'TableMenuItemCompactView',
  setup(__props) {
    const {
      tableSettings
    } = useLocalStore();
    return (_ctx, _cache) => {
      const _component_TableMenuToggle = _resolveComponent("TableMenuToggle");
      return _openBlock(), _createBlock(_component_TableMenuToggle, {
        toggle: _unref(tableSettings).compact,
        "onUpdate:toggle": _cache[0] || (_cache[0] = $event => _unref(tableSettings).compact = $event)
      }, {
        default: _withCtx(() => [_createTextVNode(" Compact view ")]),
        _: 1
      }, 8, ["toggle"]);
    };
  }
};