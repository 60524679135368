import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "p-1"
};
const _hoisted_2 = {
  key: 0,
  class: "text-slate-500 text-xs py-1.5 px-2"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$slots.header ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "header")])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default")]);
}