import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";

import { ref } from 'vue';
import { copyDataToClipboard } from '@/utils';
export default {
  __name: 'BaseCopyIcon',
  props: {
    data: {
      type: [Array, String],
      required: true
    },
    size: {
      type: String,
      default: "md"
    }
  },
  setup(__props) {
    const props = __props;
    const copied = ref(false);
    function copy() {
      copyDataToClipboard(props.data);
      copied.value = true;
      setTimeout(() => {
        copied.value = false;
      }, 1200);
    }
    return (_ctx, _cache) => {
      const _component_hero_clipboard_document_check = _resolveComponent("hero-clipboard-document-check");
      const _component_hero_clipboard_document = _resolveComponent("hero-clipboard-document");
      const _component_BaseButton = _resolveComponent("BaseButton");
      return _openBlock(), _createBlock(_component_BaseButton, {
        size: "smSquare",
        styles: "secondary",
        onClick: copy
      }, {
        default: _withCtx(() => [_unref(copied) ? (_openBlock(), _createBlock(_component_hero_clipboard_document_check, {
          key: 0,
          class: _normalizeClass(["text-green-600", {
            'w-5 h-5': props.size === 'lg',
            'w-4 h-4': props.size === 'md',
            'w-3 h-3': props.size === 'sm'
          }])
        }, null, 8, ["class"])) : (_openBlock(), _createBlock(_component_hero_clipboard_document, {
          key: 1,
          class: _normalizeClass({
            'w-5 h-5': props.size === 'lg',
            'w-4 h-4': props.size === 'md',
            'w-3 h-3': props.size === 'sm'
          })
        }, null, 8, ["class"]))]),
        _: 1
      });
    };
  }
};