import { api } from "@/api"
import { processPages, scorePages } from "@/utils"

export async function getTag(libraryId, tagId) {
	const res = await api.get(`/api/libraries/${libraryId}/tags/${tagId}/`)

	if (res?.data) {
		const tag = res.data
		if (tag.pages?.length > 0) {
			tag.pages = processPages(tag.pages)
			tag.pages = scorePages(tag.pages)
		}
		return tag
	} else {
		return
	}
}
