import { useModel as _useModel } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";

import { useLibraryStore } from '@/stores';
const _hoisted_1 = {
  class: "flex items-center justify-between gap-x-2"
};
export default {
  __name: 'TopicExplorerTableKeywords',
  props: {
    "conf": {
      type: Object,
      required: true
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const libraryStore = useLibraryStore();
    const conf = _useModel(__props, "conf");
    return (_ctx, _cache) => {
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_TableMenu = _resolveComponent("TableMenu");
      const _component_KeywordsColumnKeyword = _resolveComponent("KeywordsColumnKeyword");
      const _component_KeywordsColumnVolume = _resolveComponent("KeywordsColumnVolume");
      const _component_KeywordsColumnCompetition = _resolveComponent("KeywordsColumnCompetition");
      const _component_KeywordsColumnClicks = _resolveComponent("KeywordsColumnClicks");
      const _component_KeywordsColumnImpressions = _resolveComponent("KeywordsColumnImpressions");
      const _component_KeywordsColumnRank = _resolveComponent("KeywordsColumnRank");
      const _component_KeywordsColumnTopRankingPage = _resolveComponent("KeywordsColumnTopRankingPage");
      const _component_TableColumn = _resolveComponent("TableColumn");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: conf.value,
        "onUpdate:conf": _cache[0] || (_cache[0] = $event => conf.value = $event)
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_TableNavCopy, {
          class: "ml-auto",
          items: conf.value.getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_TableMenu)])]),
        columns: _withCtx(() => [_createVNode(_component_KeywordsColumnKeyword, {
          class: _normalizeClass({
            'w-full': !_unref(libraryStore).gscSiteurl,
            'w-7/12': _unref(libraryStore).gscSiteurl
          }),
          "search-term": conf.value.lastSearched
        }, null, 8, ["class", "search-term"]), _createVNode(_component_KeywordsColumnVolume, {
          class: _normalizeClass({
            'w-[100px]': !_unref(libraryStore).gscSiteurl,
            'w-[70px]': _unref(libraryStore).gscSiteurl
          })
        }, null, 8, ["class"]), _createVNode(_component_KeywordsColumnCompetition, {
          class: _normalizeClass({
            'w-[100px]': !_unref(libraryStore).gscSiteurl,
            'w-[70px]': _unref(libraryStore).gscSiteurl
          })
        }, null, 8, ["class"]), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_KeywordsColumnClicks, {
          key: 0,
          class: "w-[70px]"
        })) : _createCommentVNode("", true), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_KeywordsColumnImpressions, {
          key: 1,
          class: "w-[70px]"
        })) : _createCommentVNode("", true), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_KeywordsColumnRank, {
          key: 2,
          class: "w-24"
        })) : _createCommentVNode("", true), _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_KeywordsColumnTopRankingPage, {
          key: 3,
          class: "w-5/12"
        })) : _createCommentVNode("", true), !_unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_TableColumn, {
          key: 4,
          class: "w-5/12"
        })) : _createCommentVNode("", true)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 3
      }, 8, ["conf"]);
    };
  }
};