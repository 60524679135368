import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'TableMenuItem',
  props: {
    disabled: Boolean
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["w-full h-[36px] hover:cursor-pointer hover:bg-zinc-100 relative flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none", {
          'pointer-events-none opacity-50': __props.disabled
        }])
      }, [_renderSlot(_ctx.$slots, "default")], 2);
    };
  }
};