import { useModel as _useModel } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "flex gap-x-2"
};
import { useRouteQuery } from "@vueuse/router";

import { useUserStore, useLocalStore } from '@/stores';
import { ref, onMounted } from 'vue';
export default {
  __name: 'LibraryPagesTable',
  props: {
    "conf": {
      type: Object
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const userStore = useUserStore();
    const {
      pageTableSettings: settings,
      tableSettings
    } = useLocalStore();
    const conf = _useModel(__props, "conf");
    const monthRange = useRouteQuery("month_range", 3, {
      transform: Number
    });
    const monthRangeOptions = ref([{
      title: "3 Month",
      value: 3
    }, {
      title: "6 Month",
      value: 6
    }, {
      title: "12 Month",
      value: 12
    }]);
    onMounted(() => {
      conf.value.get();
    });
    return (_ctx, _cache) => {
      const _component_TableSearch = _resolveComponent("TableSearch");
      const _component_PageTableNavFilters = _resolveComponent("PageTableNavFilters");
      const _component_PageTableNavResetFilters = _resolveComponent("PageTableNavResetFilters");
      const _component_BaseTabs = _resolveComponent("BaseTabs");
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_TableMenuGroupCopy = _resolveComponent("TableMenuGroupCopy");
      const _component_TableMenuItemCompactView = _resolveComponent("TableMenuItemCompactView");
      const _component_TableMenuItemPageKey = _resolveComponent("TableMenuItemPageKey");
      const _component_TableMenuGroup = _resolveComponent("TableMenuGroup");
      const _component_hero_eye_16_solid = _resolveComponent("hero-eye-16-solid");
      const _component_TableMenuToggle = _resolveComponent("TableMenuToggle");
      const _component_hero_arrow_trending_down_16_solid = _resolveComponent("hero-arrow-trending-down-16-solid");
      const _component_hero_star_16_solid = _resolveComponent("hero-star-16-solid");
      const _component_hero_calendar_date_range_16_solid = _resolveComponent("hero-calendar-date-range-16-solid");
      const _component_TableMenu = _resolveComponent("TableMenu");
      const _component_PageColumnTitleOrUrl = _resolveComponent("PageColumnTitleOrUrl");
      const _component_PageColumnViews = _resolveComponent("PageColumnViews");
      const _component_PageColumnSessions = _resolveComponent("PageColumnSessions");
      const _component_PageColumnBounceRate = _resolveComponent("PageColumnBounceRate");
      const _component_PageColumnCollections = _resolveComponent("PageColumnCollections");
      const _component_PageColumnAge = _resolveComponent("PageColumnAge");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: conf.value,
        "onUpdate:conf": _cache[7] || (_cache[7] = $event => conf.value = $event),
        "link-to": "PageSingle",
        class: _normalizeClass(["min-w-[950px]", {
          'slim': _unref(tableSettings).compact
        }])
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_TableSearch, {
          class: "max-w-sm xl:max-w-md"
        }), _unref(userStore).isAdmin ? (_openBlock(), _createBlock(_component_PageTableNavFilters, {
          key: 0
        })) : _createCommentVNode("", true), _createVNode(_component_PageTableNavResetFilters), _createVNode(_component_BaseTabs, {
          tabs: _unref(monthRangeOptions),
          "onUpdate:tabs": _cache[0] || (_cache[0] = $event => _isRef(monthRangeOptions) ? monthRangeOptions.value = $event : null),
          selected: _unref(monthRange),
          "onUpdate:selected": _cache[1] || (_cache[1] = $event => _isRef(monthRange) ? monthRange.value = $event : null),
          class: "ml-auto mr-8"
        }, null, 8, ["tabs", "selected"]), _createVNode(_component_TableNavCopy, {
          items: conf.value.getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_TableMenu, null, {
          default: _withCtx(() => [_createVNode(_component_TableMenuGroupCopy), _createVNode(_component_TableMenuGroup, null, {
            default: _withCtx(() => [_createVNode(_component_TableMenuItemCompactView), _createVNode(_component_TableMenuItemPageKey)]),
            _: 1
          }), _createVNode(_component_TableMenuGroup, null, {
            default: _withCtx(() => [_createVNode(_component_TableMenuToggle, {
              toggle: _unref(settings).views,
              "onUpdate:toggle": _cache[2] || (_cache[2] = $event => _unref(settings).views = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_hero_eye_16_solid, {
                class: "h-4 w-4 mr-2"
              }), _createTextVNode("Views ")]),
              _: 1
            }, 8, ["toggle"]), _createVNode(_component_TableMenuToggle, {
              toggle: _unref(settings).sessions,
              "onUpdate:toggle": _cache[3] || (_cache[3] = $event => _unref(settings).sessions = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_hero_eye_16_solid, {
                class: "h-4 w-4 mr-2"
              }), _createTextVNode("Sessions ")]),
              _: 1
            }, 8, ["toggle"]), _createVNode(_component_TableMenuToggle, {
              toggle: _unref(settings).bounceRate,
              "onUpdate:toggle": _cache[4] || (_cache[4] = $event => _unref(settings).bounceRate = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_hero_arrow_trending_down_16_solid, {
                class: "h-4 w-4 mr-2"
              }), _createTextVNode("Bounce Rate ")]),
              _: 1
            }, 8, ["toggle"]), _createVNode(_component_TableMenuToggle, {
              toggle: _unref(settings).tags,
              "onUpdate:toggle": _cache[5] || (_cache[5] = $event => _unref(settings).tags = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_hero_star_16_solid, {
                class: "h-4 w-4 mr-2"
              }), _createTextVNode("Collections ")]),
              _: 1
            }, 8, ["toggle"]), _createVNode(_component_TableMenuToggle, {
              toggle: _unref(settings).age,
              "onUpdate:toggle": _cache[6] || (_cache[6] = $event => _unref(settings).age = $event)
            }, {
              default: _withCtx(() => [_createVNode(_component_hero_calendar_date_range_16_solid, {
                class: "h-4 w-4 mr-2"
              }), _createTextVNode("First Seen ")]),
              _: 1
            }, 8, ["toggle"])]),
            _: 1
          })]),
          _: 1
        })])]),
        columns: _withCtx(() => [_createVNode(_component_PageColumnTitleOrUrl, {
          "title-url-toggle": _unref(settings).titleUrlToggle,
          class: "w-full"
        }, null, 8, ["title-url-toggle"]), _unref(settings).views ? (_openBlock(), _createBlock(_component_PageColumnViews, {
          key: 0,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).sessions ? (_openBlock(), _createBlock(_component_PageColumnSessions, {
          key: 1,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).bounceRate ? (_openBlock(), _createBlock(_component_PageColumnBounceRate, {
          key: 2,
          "month-range": _unref(monthRange),
          class: "w-[95px] 2xl:w-[105px]"
        }, null, 8, ["month-range"])) : _createCommentVNode("", true), _unref(settings).tags ? (_openBlock(), _createBlock(_component_PageColumnCollections, {
          key: 3,
          class: "w-[190px] 2xl:w-[250px]"
        })) : _createCommentVNode("", true), _unref(settings).age ? (_openBlock(), _createBlock(_component_PageColumnAge, {
          key: 4,
          class: "w-20 xl:w-[100px]"
        })) : _createCommentVNode("", true)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 1
      }, 8, ["conf", "class"]);
    };
  }
};