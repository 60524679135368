import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";

import { inject, computed } from 'vue';
export default {
  __name: 'KeywordsColumnCompetition',
  props: {
    viewLength: {
      type: String,
      default: "30 days"
    }
  },
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const data = computed(() => item ? item.competition : 0);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "competition"
      }, {
        title: _withCtx(() => [_createTextVNode(" Comp. ")]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1,
        class: _normalizeClass({
          'text-slate-300': _unref(data) === 0
        })
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(data) === 0 ? '–' : _unref(data)), 1)]),
        _: 1
      }, 8, ["class"]));
    };
  }
};