import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";

import { inject, computed } from 'vue';
const _hoisted_1 = ["colspan"];
const _hoisted_2 = ["target", "href"];
const _hoisted_3 = {
  key: 2,
  class: "block w-full h-full px-3 flex items-center"
};
export default {
  __name: 'TableCell',
  props: {
    link: {
      type: [String, null],
      default: null
    },
    to: {
      type: [Object, String],
      default: () => null
    },
    item: {
      type: [Object, null],
      default: () => null
    },
    target: {
      type: String,
      default: null
    },
    colspan: {
      type: Number,
      default: 1
    },
    truncate: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const itemInject = inject("item", null);
    const linkInject = inject("linkTo", null);
    const targetInject = inject("target", null);
    const props = __props;

    // Either use the passed prop version, or default to the injected version
    const compLink = computed(() => props.link || linkInject);
    const compItem = computed(() => props.item || itemInject);
    const compTarget = computed(() => props.target || targetInject);
    const toRoute = computed(() => {
      if (compLink.value === "PageSingle" && compItem.value?.id) {
        return {
          name: "PageSingle",
          params: {
            pageId: parseInt(compItem.value?.id)
          }
        };
      } else {
        return props.to;
      }
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("td", {
        colspan: __props.colspan
      }, [_createElementVNode("div", {
        class: _normalizeClass(["h-full w-full", {
          truncate: __props.truncate
        }])
      }, [_unref(compLink) === 'External' && _unref(toRoute) ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        target: _unref(compTarget),
        href: _unref(toRoute),
        class: "block w-full h-full px-3 flex items-center"
      }, [_renderSlot(_ctx.$slots, "default")], 8, _hoisted_2)) : _unref(compLink) && _unref(toRoute) ? (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        target: _unref(compTarget),
        to: _unref(toRoute),
        class: "block w-full h-full px-3 flex items-center"
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 8, ["target", "to"])) : (_openBlock(), _createElementBlock("span", _hoisted_3, [_renderSlot(_ctx.$slots, "default")]))], 2)], 8, _hoisted_1);
    };
  }
};