import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "divide-y"
};
export function render(_ctx, _cache) {
  const _component_hero_ellipsis_vertical_solid = _resolveComponent("hero-ellipsis-vertical-solid");
  const _component_BaseButton = _resolveComponent("BaseButton");
  const _component_PopoverButton = _resolveComponent("PopoverButton");
  const _component_TableMenuGroupCopy = _resolveComponent("TableMenuGroupCopy");
  const _component_PopoverPanel = _resolveComponent("PopoverPanel");
  const _component_Popover = _resolveComponent("Popover");
  return _openBlock(), _createBlock(_component_Popover, {
    as: "div",
    class: "relative",
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
  }, {
    default: _withCtx(() => [_createVNode(_component_PopoverButton, null, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "button", {}, () => [_createVNode(_component_BaseButton, {
        size: "mdSquare",
        class: "flex items-center justify-center"
      }, {
        default: _withCtx(() => [_createVNode(_component_hero_ellipsis_vertical_solid, {
          class: "w-5 h-5"
        })]),
        _: 1
      })])]),
      _: 3
    }), _createVNode(_component_PopoverPanel, {
      class: "z-50 absolute top-[45px] right-0 w-[240px] overflow-hidden rounded-md border bg-white shadow select-none",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, () => [_createVNode(_component_TableMenuGroupCopy)])])]),
      _: 3
    })]),
    _: 3
  });
}