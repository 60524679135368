import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";

import { inject } from 'vue';
export default {
  __name: 'TableColumn',
  props: {
    sortBy: {
      type: [String, null],
      default: null
    }
  },
  setup(__props) {
    const header = inject("header", false);
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCell = _resolveComponent("TableCell");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": __props.sortBy
      }, _createSlots({
        _: 2
      }, [_ctx.$slots.title ? {
        name: "title",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
        key: "0"
      } : undefined, _ctx.$slots.tooltip ? {
        name: "tooltip",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "tooltip")]),
        key: "1"
      } : undefined]), 1032, ["sort-by"])) : (_openBlock(), _createBlock(_component_TableCell, {
        key: 1
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }));
    };
  }
};