import { unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "pl-4 text-slate-500 text-xs"
};
import { numDisplay } from "@/utils";

import { inject, computed } from 'vue';
export default {
  __name: 'PageColumnViewGrowth',
  props: {
    monthRange: {
      type: Number,
      required: true
    }
  },
  setup(__props) {
    const header = inject("header", false);
    const item = inject("item", null);
    const props = __props;
    const positiveGrowth = computed(() => item ? item?.calcs?.opportunityGrowthMetric * props.monthRange : undefined);
    const data = computed(() => {
      if (!item) return;
      const regularViews = item[`org${props.monthRange}Month`].pageviews;
      return positiveGrowth.value + regularViews || undefined;
    });
    return (_ctx, _cache) => {
      const _component_TableCellHeader = _resolveComponent("TableCellHeader");
      const _component_TableCellNumber = _resolveComponent("TableCellNumber");
      return _unref(header) ? (_openBlock(), _createBlock(_component_TableCellHeader, {
        key: 0,
        "sort-by": "calcs.opportunityGrowthMetric"
      }, {
        title: _withCtx(() => [_createTextVNode(" Growth Est. ")]),
        tooltip: _withCtx(() => [_createTextVNode(" Our estimated " + _toDisplayString(props.monthRange) + " month organic traffic growth from taking recommended actions. ", 1)]),
        _: 1
      })) : (_openBlock(), _createBlock(_component_TableCellNumber, {
        key: 1,
        data: _unref(data),
        format: "l"
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, "(+" + _toDisplayString(_unref(numDisplay)(_unref(positiveGrowth), "m")) + ")", 1)]),
        _: 1
      }, 8, ["data"]));
    };
  }
};